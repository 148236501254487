import { useState } from 'react';

import Copy from '@/components/Icons/Copy';
import { classNames, hashTruncate } from '@/helpers/strings';
import IContacts from '@/types/IContacts';

type ContactsListItemProps = {
  contact: IContacts;
};

export default function ContactsListItem({ contact }: ContactsListItemProps) {
  const {
    profile,
    email,
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    company_name: companyName,
    phone_number: phoneNumber,
  } = contact;

  const [clipboardCopy, setClipboardCopy] = useState(false);
  const isProfileDeactivated = ['cleared', 'deleted'].includes(
    profile?.status || '',
  );

  const getStatusPrefix = () => {
    const status = profile?.status || '';

    if (status === 'cleared') {
      return '(Cleared) ';
    }

    if (status === 'deleted') {
      return '(Deactivated) ';
    }

    return '';
  };

  return (
    <li>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-4 xl:grid-cols-6 md:gap-4">
              <div className="col-span-4 md:col-span-2 flex flex-col w-full">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {firstName} {lastName}
                </p>
                {email && (
                  <p className="text-sm text-gray-500 truncate">{email}</p>
                )}
              </div>
              <div className="md:hidden col-span-4 xl:col-span-2 xl:flex items-center">
                <div>
                  <p className="text-sm text-gray-900">
                    <span className="md:hidden">Title: </span>
                    {jobTitle}
                  </p>
                  {companyName && (
                    <p className="text-sm text-gray-500">
                      <span className="md:hidden">Company: </span>
                      {companyName}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-4 md:col-span-1 flex items-center">
                {phoneNumber && (
                  <p className="text-sm text-gray-900 truncate">
                    <span className="md:hidden">Phone: </span>
                    {phoneNumber}
                  </p>
                )}
              </div>
              <div className="col-span-4 md:col-span-1 flex flex-col">
                <p
                  className={classNames(
                    'text-sm truncate',
                    isProfileDeactivated
                      ? 'text-sm text-gray-400'
                      : 'text-sm text-gray-900',
                  )}
                >
                  <span className="md:hidden">Connection: </span>
                  {`${profile?.first_name} ${profile?.last_name}`}
                </p>
                {isProfileDeactivated && (
                  <p className="flex text-sm font-medium text-gray-400">
                    {getStatusPrefix()}
                  </p>
                )}
                {!isProfileDeactivated && (
                  <p className="flex text-sm font-medium text-gray-500">
                    <span className="md:hidden">Connection&nbsp;</span>
                    ID:&nbsp;
                    <button
                      type="button"
                      className="appearance-none text-brand-600 text-sm font-medium flex items-center"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(profile.profile_hash)
                          .then(() => setClipboardCopy(true))
                          .finally(() =>
                            setTimeout(() => {
                              setClipboardCopy(false);
                            }, 2000),
                          );
                      }}
                    >
                      {clipboardCopy
                        ? 'Copied!'
                        : hashTruncate(profile.profile_hash)}
                      <Copy className="h-4 w-4 ml-1" />
                    </button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
