import { useState } from 'react';

import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { useUpdateEffect } from 'usehooks-ts';

import { classNames } from '@/helpers/strings';

import { BUTTON_KIND } from '../Button';
import Modal from '../Modals/Modal';
import ModalFullWidth from '../Modals/ModalFullWidth';

const DISCLAIMERS = [
  {
    header: 'Profiles:',
    type: 'warning',
    bullet_points: [
      'Will be permanently deleted and inaccessible.',
      'Profile sharing and editing will not be possible.',
    ],
    isPlaceholder: false,
  },
  {
    header: 'Analytics:',
    type: 'info',
    bullet_points: [
      'User analytics will be wiped',
      'Organisation and group analytics (tapt+ only) will remain.',
    ],
    isPlaceholder: false,
  },
  {
    header: 'Card:',
    type: 'warning',
    bullet_points: ['Card will be deactivated and rendered non-functional.'],
    isPlaceholder: false,
  },
  {
    header: 'Contacts:',
    type: 'info',
    bullet_points: ['Contacts will remain in the organisation contacts page.'],
    isPlaceholder: false,
  },
  {
    header: 'Access to the Tapt App:',
    type: 'warning',
    bullet_points: [
      'Profile owner will be logged out and all access will be revoked.',
      'Account and unsaved data will be inaccessible to the user.',
    ],
    isPlaceholder: false,
  },
  {
    header: '',
    bullet_points: [],
    isPlaceholder: true,
  },
  {
    header: 'Groups (tapt+ only):',
    type: 'warning',
    bullet_points: ['Profile will be removed from associated group.'],
    isPlaceholder: false,
  },
  {
    header: '',
    bullet_points: [],
    isPlaceholder: true,
  },
  {
    header: 'Active directory (tapt+ only):',
    type: 'warning',
    bullet_points: ['Sync will be removed.'],
    isPlaceholder: false,
  },
  {
    header: '',
    bullet_points: [],
    isPlaceholder: true,
  },
];

export interface IWarningModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
}

export default function DeleteProfileWarningModal({
  isOpen,
  setIsOpen,
  onSuccess,
}: IWarningModal): JSX.Element {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(isOpen);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useUpdateEffect(() => {
    setIsWarningModalOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <ModalFullWidth
        isOpen={isWarningModalOpen}
        setIsOpen={setIsWarningModalOpen}
        dialogTitle=""
        onSuccess={() => {
          setIsConfirmationModalOpen(true);
          setIsWarningModalOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        successButtonText="Proceed to delete"
        cancelButtonText="Cancel"
        successButtonKind={BUTTON_KIND.RED}
      >
        <>
          <div className="border-b border-gray-200 pb-5">
            <h3 className="text-2xl text-gray-900 font-medium mb-2">
              <span className="font-semibold">Warning:</span> Consider the
              impact of deleting your profile.
            </h3>
            <p className="font-normal text-gray-500 text-base">
              Before proceeding, please take a moment to review the consequences
              of deleting your profile.
            </p>
          </div>
          <ul className="flex flex-row flex-wrap w-full">
            {DISCLAIMERS.map((disclaimer, index) => (
              <li
                key={`delete-profile-disclaimer-${index}`}
                className={classNames(
                  'w-5/12 flex-grow flex pb-2 mt-5',
                  index % 2 === 0 ? 'ml-5' : '',
                  disclaimer.isPlaceholder ? '' : 'border-b border-gray-200',
                )}
              >
                {disclaimer.type === 'warning' && (
                  <XMarkIcon
                    className="h-6 w-6 flex-shrink-0 text-red-500"
                    aria-hidden="true"
                  />
                )}

                {disclaimer.type === 'info' && (
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-emerald-500"
                    aria-hidden="true"
                  />
                )}
                <div className="ml-3">
                  <span className="text-gray-900 font-medium">
                    {disclaimer.header}
                  </span>
                  <ul className="list-disc text-gray-500 text-xs leading-5 font-normal">
                    {disclaimer.bullet_points.map((bullet_point, index) => (
                      <li
                        key={`delete-profile-disclaimer-bullet-point-${index}`}
                        className="ml-6"
                      >
                        {bullet_point}
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </>
      </ModalFullWidth>

      <Modal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        dialogTitle=""
        successButtonText="Delete profile"
        onSuccess={() => {
          onSuccess();
          setIsConfirmationModalOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
          setIsConfirmed(false);
        }}
        onClose={() => {
          setIsOpen(false);
          setIsConfirmed(false);
        }}
        successButtonKind={BUTTON_KIND.RED}
        isDisabled={!isConfirmed}
      >
        <div className="mb-5">
          <h3 className="text-2xl text-gray-900 font-medium mb-2">
            <span className="font-semibold">Warning:</span> Deleting this
            profile is permanent.
          </h3>
          <p className="font-normal text-gray-500 text-sm">
            By proceeding, you acknowledge that you will permanently lose access
            to the profile, associated data and analytics. Any connected card
            will be rendered non-functional.
          </p>
        </div>

        <div className="w-full flex justify-start space-x-4 items-center">
          <input
            id="delete-profile-confirmation-checkbox"
            type="checkbox"
            className="focus:ring-brand-500 h-4 w-4 text-brand-500 border-gray-300 rounded"
            defaultChecked={isConfirmed}
            onClick={() => {
              setIsConfirmed?.(!isConfirmed);
            }}
          />
          <label
            className="text-sm"
            htmlFor="delete-profile-confirmation-checkbox"
          >
            I am sure I want to deactivate & delete
          </label>
        </div>
      </Modal>
    </>
  );
}
