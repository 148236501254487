import { useEffect, useState } from 'react';

import { Tab, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import Layout from '@/components/Layout';
import OrdersTab from '@/components/ShopPage/OrdersTab';
import { ReplacementProducts } from '@/components/ShopPage/ReplacementProducts';
import TabList from '@/components/TabList';
import useAppState from '@/hooks/useAppState';

function ReplacementCardsBadge() {
  return (
    <div className="flex items-center border border-amber-300 bg-amber-50 rounded-md text-sm p-2 font-medium gap-2 self-end w-full md:w-auto">
      <InformationCircleIcon
        className="w-5 h-5 text-amber-500 self-start"
        strokeWidth={2}
      />
      <span className="text-amber-800 font-medium">
        Select multiple profiles to reorder in bulk
      </span>
    </div>
  );
}

const tabs = [
  {
    name: 'Design',
    value: 'design',
    current: true,
  },
  {
    name: 'Replacement Products',
    value: 'replacement-products',
    current: false,
  },
];

export default function ShopPage() {
  const { shopTab, selectShopTab } = useAppState();

  const [selectedIndex, setSelectedIndex] = useState(
    tabs[shopTab] ? shopTab : 0,
  );

  useEffect(() => {
    setSelectedIndex(shopTab);
  }, [shopTab]);

  const tab = tabs[selectedIndex].value;

  return (
    <Layout
      pageName="Orders"
      className="bg-gray-50"
      rightTitle={
        tab === 'replacement-products' ? <ReplacementCardsBadge /> : undefined
      }
    >
      <Transition show>
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={index => {
            setSelectedIndex(index);
            selectShopTab(index);
          }}
        >
          <div className="flex flex-col items-start md:items-center justify-between w-full lg:border-b border-gray-200 space-y-4">
            <div className="w-full md:w-auto self-start">
              <TabList tabs={tabs} value={tab} />
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel className="outline-none">
              <OrdersTab />
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <ReplacementProducts />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Transition>
    </Layout>
  );
}
