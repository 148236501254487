import React, { useEffect, useState, useMemo } from 'react';
import { Document, Page } from 'react-pdf';
import { version as pdfjsVersion }  from 'pdfjs-dist';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowDownTrayIcon as DownloadIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';

import styled from 'styled-components';

import filesAPI from '@/api/files';
import { classNames } from '@/helpers/strings';
import {
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import { RenderCurrentScaleProps, RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom';

// Memoized styles object to prevent recreation on each render
const containerStyles = {
  overflow: 'auto' as const,
  scrollBehavior: 'smooth' as const,
  paddingTop: '26px',
  height: '100%',
  borderTop: '1px solid #E5E7EB',
  '& .rpv-core__viewer': {
    border: 'none',
  },
  '& .rpv-core__viewer.rpv-core__viewer--light': {
    border: 'none',
    backgroundColor: '#F3F4F6',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  '& div[data-testid="core__inner-pages"], .rpv-thumbnail__list, .rpv-core__viewer': {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      backgroundColor: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#652DBF',
      borderRadius: '100px',
    },
  },
  '& .thumbnail__list': {
    marginBottom: '6px',
    backgroundColor: '#F3F4F6',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  '& .rpv-thumbnail__list': {
    flexFlow: 'column',
    justifyContent: 'flex-start',
  },
  '& .rpv-thumbnail__list > div': {
    margin: '2.5px 0',
  },
  '& .rpv-thumbnail__item': {
    padding: '5px',
    '& .rpv-thumbnail__container': {
      borderRadius: '5px',
      overflow: 'hidden',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
  },
  '& .rpv-core__inner-page': {
    backgroundColor: '#F3F4F6',
    '& .rpv-core__page-layer': {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
  },
  '& .rpv-thumbnail__item--selected, .rpv-thumbnail__item:focus, .rpv-thumbnail__item:hover': {
    background: '#E1CEFF',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
  },
  '& .rpv-thumbnail__index': {
    display: 'none',
  },
  '& .rpv-core__viewer.flex.h-full > .overflow-auto': {
    width: '269px',
  },
  '& .rpv-core__viewer.flex.h-full:last-child': {
    width: '100%',
  },
  '& .react-pdf__Page__canvas': {
    width: '100%!important',
    height: 'auto!important',
  },
  '& .react-pdf__Page__textContent, .react-pdf__Page__annotations': {
    display: 'none!important',
  },
  '.react-pdf__Document .react-pdf__Page canvas.react-pdf__Page__canvas': {
    padding: '4px',
    paddingTop: '0px',
    paddingRight: '0px',
  },
  '.react-pdf__Document': {
    paddingY: '4px',
  },
} as const;

// Create styled component outside of the main component
const PDFContainer = styled.div(containerStyles);

interface IPdfPreviewComponent {
  orderId: number | undefined;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  designFile?: any;
  setSuccess: React.Dispatch<React.SetStateAction<string>>;
}
export type Ref = {
  downLoadDesign(): void;
};

export const PdfPreView = ({
  orderId,
  setError,
  setSuccess,
  designFile,
}: IPdfPreviewComponent) => {
  const [file, setFile] = useState<string>('');
  type ScreenSize = 'mobile' | 'desktop';
  
  // Initialize screen size based on window width
  const [screenSize, setScreenSize] = useState<ScreenSize>(() => 
    window.innerWidth <= 425 ? 'mobile' : 'desktop'
  );
  const [numPages, setNumPages] = useState<number>(0);

  // Helper variables to make JSX conditions more readable and maintainable
  const isMobile = screenSize === 'mobile';
  const isDesktop = screenSize === 'desktop';

  // Memoize plugin instances to prevent recreation on each render
  const toolbarPluginInstance = toolbarPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();

  // Destructure plugin components after memoization
  const { Thumbnails } = thumbnailPluginInstance;
  const { Toolbar } = toolbarPluginInstance;

  // Handle file download
  useEffect(() => {
    let mounted = true;

    const downloadFile = async () => {
      if (!orderId || !designFile?.original_url) {
        return;
      }

      try {
        const blob = await filesAPI.downloadFile(designFile.original_url);
        if (mounted && blob?.data) {
          const objectUrl = window.URL.createObjectURL(blob.data);
          setFile(objectUrl);
        }
      } catch (error) {
        if (mounted) {
          setError(true);
        }
      }
    };

    downloadFile();

    return () => {
      mounted = false;
      if (file) {
        URL.revokeObjectURL(file);
      }
    };
  }, [orderId, designFile]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth <= 425 ? 'mobile' : 'desktop');
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <PDFContainer>
      <Worker
        workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}
      />
      {file && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
          className="h-full"
        >
          {!isMobile && (
            <div className="mb-5 flex">
              <div className="h-full lg:w-64 "> </div>
              <Toolbar>
                {({
                  CurrentPageLabel,
                  CurrentScale,
                  GoToNextPage,
                  GoToPreviousPage,
                  ZoomIn,
                  ZoomOut,
                  Download,
                }: any) => {
                  return (
                    <div className="flex justify-between align-middle w-full flex-wrap gap-3">
                      <div className="flex justify-between align-middle ">
                        <div className="pdf-paginate flex justify-between items-center border lg:h-full rounded-md">
                          <GoToNextPage>
                            {({ isDisabled, onClick }: RenderGoToPageProps) => (
                              <button
                                style={{
                                  cursor: isDisabled
                                    ? 'not-allowed'
                                    : 'pointer',
                                }}
                                disabled={isDisabled}
                                onClick={onClick}
                                className="hover:text-brand-400 lg:pl-7 lg:pr-2 pl-4 pr-2 py-2 lg:h-full text-gray-700 focus:outline-none outline-none"
                              >
                                <ArrowDownIcon className="stroke-1.3 z-20 lg:h-5 lg:w-5 w-3 h-3" />
                              </button>
                            )}
                          </GoToNextPage>

                          <CurrentPageLabel>
                            {(props: any) => (
                              <span className="text-gray-700 text-xs font-normal">{`${
                                Number(props.currentPage) + 1
                              } / ${props.numberOfPages}`}</span>
                            )}
                          </CurrentPageLabel>

                          <GoToPreviousPage>
                            {({ isDisabled, onClick }: RenderGoToPageProps) => (
                              <button
                                style={{
                                  cursor: isDisabled
                                    ? 'not-allowed'
                                    : 'pointer',
                                }}
                                className="hover:text-brand-400 lg:pl-2 lg:pr-7 pr-4 pl-2 py-1 lg:h-full text-gray-700 focus:outline-none outline-none"
                                disabled={isDisabled}
                                onClick={onClick}
                              >
                                <ArrowUpIcon className="stroke-1.3 z-20 lg:h-5 lg:w-5 w-3 h-3" />
                              </button>
                            )}
                          </GoToPreviousPage>
                        </div>

                        <div className="pdf-paginate flex justify-between items-center border lg:h-full rounded-md ml-3">
                          <ZoomOut>
                            {({ onClick }: RenderZoomOutProps) => (
                              <button
                                onClick={onClick}
                                className="hover:text-brand-400 lg:pl-7 lg:pr-2 pl-4 pr-2 py-2 lg:h-full text-gray-700 focus:outline-none outline-none"
                              >
                                <MinusIcon className="stroke-1.3 z-20 lg:h-5 lg:w-5 h-3 w-3" />
                              </button>
                            )}
                          </ZoomOut>

                          <CurrentScale>
                            {(props: RenderCurrentScaleProps) => (
                              <span className="text-gray-700 text-xs font-normal">{`${Math.round(
                                props.scale * 100,
                              )}%`}</span>
                            )}
                          </CurrentScale>

                          <ZoomIn>
                            {({ onClick }: RenderZoomInProps) => (
                              <button
                                onClick={onClick}
                                className="hover:text-brand-400 pl-2 py-2 lg:pr-7 pr-4 h-full text-gray-700 focus:outline-none outline-none"
                              >
                                <PlusIcon className="stroke-1.3 z-20 lg:h-5 lg:w-5 h-3 w-3" />
                              </button>
                            )}
                          </ZoomIn>
                        </div>
                      </div>
                      <div className="py-0 px-1 ml-auto flex-right">
                        <Download>
                          {({ onClick }: RenderDownloadProps) => (
                            <button
                              className="bg-brand-500 rounded-full p-2 flex justify-center item-center lg:rounded-md text-white focus:outline-none outline-none"
                              onClick={e => {
                                try {
                                  const click = onClick();
                                  setSuccess('File downloaded successfully!');
                                  return click;
                                } catch (e) {
                                  setError(true);
                                  return () => {};
                                }
                              }}
                            >
                              <DownloadIcon className="h-5 w-5 text-white" />
                              <p className="lg:ml-2 text-sm font-medium ">
                                {isDesktop ? 'Download PDF' : ''}
                              </p>
                            </button>
                          )}
                        </Download>
                      </div>
                    </div>
                  );
                }}
              </Toolbar>
            </div>
          )}
          <div
            style={{
              flex: 1,
            }}
            className="overflow-hidden"
          >
            <div
              className={classNames(
                'rpv-core__viewer flex h-full overflow-y-scroll overflow-x-hidden',
                isMobile ? 'bg-gray-300' : '',
              )}
            >
              <div className="overflow-auto lg:block hidden">
                <Thumbnails />
              </div>
              {isMobile ? (
                <Document
                  file={designFile?.original_url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className=" h-full bg-gray-300"
                >
                  {Array.from(new Array(numPages), (e, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      className="mx-auto first:pt-1"
                    />
                  ))}
                </Document>
              ) : (
                <Viewer
                  fileUrl={file}
                  plugins={[toolbarPluginInstance, thumbnailPluginInstance]}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </PDFContainer>
  );
};
export default React.memo(PdfPreView);
