import { ITableHeader } from '@/components/OrdersPage/shared/TableHeader';
import { STAGE } from '@/constants/stage';
import { orderHandler } from '@/helpers/orderHandler';
import { classNames } from '@/helpers/strings';

const orderTableHeader = (status: string): ITableHeader[] => [
  {
    style: { width: '5%' },
    className: 'text-xs font-semibold text-gray-900 text-center',
    scope: 'col',
    children: '',
  },
  {
    style: { width: '7%' },
    className:
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    scope: 'col',
    children: 'ORDER NO.',
  },
  {
    style: { width: '9.6%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'TAG'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'TAG',
  },
  {
    style: { maxWidth: '16%' },
    className:
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase pl-10 lg:table-cell hidden',
    scope: 'col',
    children: <span className="truncate">NAME &amp; EMAIL</span>,
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'ORDER TYPE'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'ORDER TYPE',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'NOTIFICATIONS'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'NOTIFICATIONS',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'WARNING'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase lg:table-cell hidden text-center',
    ),
    scope: 'col',
    children: 'WARNING',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'CHANGES'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'CHANGES',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'QR CODE'),
      'text-gray-900 text-sm not-italic font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'QR CODE',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'EXPORT PROFILES'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'EXPORT PROFILES',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'PROOF'),
      'text-gray-900 text-sm not-italic font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'PROOF',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'LABEL'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'LABEL',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'STAGE'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'STAGE',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'PLACED'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'PLACED',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'PAUSED BY'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children: 'Paused by',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'SHOPIFY LINK'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase  lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'SHOPIFY LINK',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'SHIPPING'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase  text-center lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'SHIPPING',
  },
  {
    style: { width: '9.2%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'DESIGN FILE'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'DESIGN FILE',
  },
  {
    className: classNames(
      orderHandler.stageTabHandler(status, 'ORDER DETAILS'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase w-1/6 text-center',
    ),
    scope: 'col',
    children: 'ORDER DETAILS',
  },
  {
    style: { width: '16%' },
    className: classNames(
      orderHandler.stageTabHandler(status, 'UPLOAD DESIGN'),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center',
    ),
    scope: 'col',
    children:
      status === STAGE.order.design_edits ||
      status === STAGE.order.ready_for_print
        ? 'REUPLOAD DESIGN'
        : 'UPLOAD DESIGN',
  },
  {
    style: { width: '11%' },
    className: classNames(
      orderHandler.stageTabHandler(
        status,
        'EXPORT PROFILES FOR AWAITING CLIENT',
      ),
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase lg:table-cell hidden',
    ),
    scope: 'col',
    children: 'EXPORT PROFILES',
  },
  {
    className:
      'text-gray-900 text-sm  font-medium leading-4 tracking-[0.7px] uppercase text-center py-2 w-1/12',
    scope: 'col',
    children: 'OTHER',
  },
];
export default orderTableHeader;
