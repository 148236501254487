import { useNavigate } from 'react-router-dom';

import Button, { BUTTON_KIND } from '@/components/Button';

import Badge, { BADGE_KIND } from './Badge';

interface IOrganisationsListItem {
  id: number;
  name?: string;
  email?: string;
  noOfAccounts: number;
  noOfActiveProfiles: number;
  noOfEditingProfiles: number;
  noOfProfiles: number;
  noOfUnprintedProfiles: number;
  insertedAt: Date;
  lastLogin: Date | null;
  viewOrganisation: string;
}

export default function OrganisationsListItem({
  id,
  name,
  email,
  noOfAccounts,
  noOfActiveProfiles,
  noOfEditingProfiles,
  noOfProfiles,
  noOfUnprintedProfiles,
  insertedAt,
  lastLogin,
  viewOrganisation,
}: IOrganisationsListItem) {
  const navigate = useNavigate();

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-4 xl:grid-cols-8">
              <div className="col-span-4 md:col-span-1 flex items-center space-x-4 w-full">
                <p className="text-sm font-medium text-gray-900 truncate">
                  #{id}
                </p>
              </div>
              <div className="flex items-center">
                <div className="min-w-0">
                  <p className="text-sm text-gray-900 truncate">{name}</p>
                  {email && (
                    <p className="text-sm text-gray-900 truncate">{email}</p>
                  )}
                </div>
              </div>
              <div className="flex md:hidden xl:flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">No. of accounts: </span>
                  {noOfAccounts}
                </p>
              </div>
              <div className="flex md:hidden xl:flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">No. of profiles: </span>
                  <span className="space-x-2">
                    {noOfProfiles === 0 ? (
                      noOfProfiles
                    ) : (
                      <>
                        <Badge
                          kind={BADGE_KIND.GREEN}
                          text={`${noOfActiveProfiles}`}
                        />
                        <Badge
                          kind={BADGE_KIND.AMBER}
                          text={`${noOfEditingProfiles}`}
                        />
                        <Badge
                          kind={BADGE_KIND.RED}
                          text={`${noOfUnprintedProfiles}`}
                        />
                      </>
                    )}
                  </span>
                </p>
              </div>
              <div className="flex md:hidden xl:flex items-center">
                {insertedAt && (
                  <p className="text-sm text-gray-900">
                    {insertedAt.toLocaleString()}
                  </p>
                )}
              </div>
              <div className="flex md:hidden xl:flex items-center">
                {lastLogin && (
                  <p className="text-sm text-gray-900">
                    {lastLogin.toLocaleString()}
                  </p>
                )}
              </div>
              <div className="mt-2 md:mt-0 flex flex-col lg:flex-row lg:space-x-4 space-y-2 lg:space-y-0 items-start md:items-center md:justify-end md:col-span-2">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Log in"
                  href={`/?scope=user&uOrgID=${id}`}
                  external
                />
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="View"
                  onClick={() =>
                    navigate(viewOrganisation, {
                      state: {
                        fromName: 'Organisations',
                        fromURL: '/organisations',
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
