import { useEffect, useRef, useState } from 'react';

import QRCodeStyling from 'qr-code-styling';

import DefaultProfileImage from '@/assets/images/DefaultProfilePicture.png';
import { ReactComponent as TertiaryLogo } from '@/assets/svg/logos/primary.svg';
import { IEmailSignature } from '@/types/IOrganisation';
import { IProfileSignature } from '@/types/IProfile';

interface EmailSignaturePreviewProps {
  signature: IEmailSignature;
  profile: IProfileSignature;
}

const addRef = (url: string, ref: string) => {
  const urlObj = new URL(url);
  urlObj.searchParams.set('ref', ref);
  return urlObj.toString();
};

export default function EmailSignaturePreview({
  signature,
  profile,
}: EmailSignaturePreviewProps) {
  const [qrImageUrl, setQrImageUrl] = useState<string>('');
  const urlForQrCode = addRef(profile.profile_page_url, 'email-signature-qr');
  const urlForProfileImage = addRef(
    profile.profile_page_url,
    'email-signature-profile-image',
  );
  const urlForCTA = addRef(profile.profile_page_url, 'email-signature-cta');

  useEffect(() => {
    if (profile.profile_page_url && signature.show_qr_code) {
      const qrCode = new QRCodeStyling({
        width: 200,
        height: 200,
        data: urlForQrCode,
        margin: 1,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'Q',
        },
        imageOptions: {
          hideBackgroundDots: true,
          imageSize: 0.4,
          margin: 0,
        },
        dotsOptions: {
          type: 'rounded',
          color: '#1B3B49',
        },
        backgroundOptions: {
          color: '#e1dcdc',
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#1B3B49',
        },
        cornersDotOptions: {
          type: 'dot',
          color: '#1B3B49',
        },
      });

      // Generate QR code as PNG
      qrCode.getRawData('png').then(async data => {
        if (data) {
          let base64String: string;

          if (data instanceof Blob) {
            // Handle Blob data
            const arrayBuffer = await data.arrayBuffer();
            base64String = btoa(
              String.fromCharCode(...new Uint8Array(arrayBuffer)),
            );
          } else {
            // Handle Buffer data
            base64String = Buffer.from(data).toString('base64');
          }
          setQrImageUrl(`data:image/png;base64,${base64String}`);
        }
      });
    }
  }, [profile.profile_page_url, signature.show_qr_code]);

  const fullName = [profile.first_name, profile.middle_name, profile.last_name]
    .filter(Boolean)
    .join(' ');

  const address = [
    profile.street_address,
    profile.city,
    profile.state,
    profile.post_code,
    profile.country,
  ]
    .filter(Boolean)
    .join(', ');

  const allowedSocialLinks = [
    'linkedin',
    'twitter',
    'facebook',
    'instagram',
    'tiktok',
    'pinterest',
    'youtube',
  ];

  const iconStyle = {
    width: '13px',
    height: '13px',
    marginRight: '5px',
    display: 'inline-block',
    verticalAlign: 'middle',
  };

  return (
    <div
      style={{ backgroundColor: signature.background_color, padding: '20px' }}
      className="rounded-md"
    >
      <table
        cellPadding="0"
        width="500"
        style={{ borderCollapse: 'collapse', fontSize: '12.3px' }}
      >
        <tbody>
          <tr>
            <td style={{ margin: '0.1px', padding: '0' }}>
              <table cellPadding="0" style={{ borderCollapse: 'collapse' }}>
                <tbody>
                  <tr>
                    {signature.show_profile_image && profile.photo && (
                      <td
                        style={{
                          margin: '0.1px',
                          padding: '0 15px 0 0',
                          verticalAlign: 'top',
                        }}
                      >
                        <a href={urlForProfileImage}>
                          <img
                            src={
                              profile.photo.medium_url || DefaultProfileImage
                            }
                            alt={fullName}
                            style={{
                              width: '100px',
                              height: '100px',
                              borderRadius: '50%',
                            }}
                          />
                        </a>
                      </td>
                    )}
                    <td style={{ margin: '0.1px', padding: '0' }} valign="top">
                      <table
                        cellPadding="0"
                        style={{ borderCollapse: 'collapse' }}
                      >
                        <tbody>
                          {signature.show_full_name && fullName && (
                            <tr>
                              <td
                                style={{
                                  margin: '0.1px',
                                  padding: '0 0 2px',
                                  font: '14.8px/18.8px Poppins, sans-serif',
                                  color: signature.primary_text_color,
                                }}
                              >
                                <span style={{ fontWeight: 600 }}>
                                  {fullName}
                                </span>
                              </td>
                            </tr>
                          )}
                          {signature.show_job_title && profile.job_title && (
                            <tr>
                              <td
                                style={{
                                  margin: '0.1px',
                                  padding: '0 0 12px',
                                  font: '12.3px/15.7px Poppins, sans-serif',
                                  color: signature.text_color,
                                  fontWeight: 600,
                                }}
                              >
                                {profile.job_title}
                                {signature.show_company_name &&
                                  profile.company_name && (
                                    <span>&nbsp;{profile.company_name}</span>
                                  )}
                              </td>
                            </tr>
                          )}
                          {signature.show_mobile_number &&
                            profile.mobile_number && (
                              <tr>
                                <td
                                  style={{
                                    margin: '0.1px',
                                    padding: '1px 0',
                                    font: '12.3px/15.7px Poppins, sans-serif',
                                    color: signature.text_color,
                                  }}
                                >
                                  <img
                                    src="https://assets.tapt.io/email/mobile.png"
                                    alt="mobile"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      display: 'inline-block',
                                      position: 'relative',
                                      top: '-1px',
                                      marginRight: '8px',
                                    }}
                                  />
                                  {profile.mobile_number}
                                </td>
                              </tr>
                            )}
                          {signature.show_email && profile.email && (
                            <tr>
                              <td
                                style={{
                                  margin: '0.1px',
                                  padding: '1px 0',
                                  font: '12.3px/15.7px Poppins, sans-serif',
                                  color: signature.text_color,
                                }}
                              >
                                <img
                                  src="https://assets.tapt.io/email/mail.png"
                                  alt="mail"
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '-1px',
                                    marginRight: '8px',
                                  }}
                                />
                                <a
                                  href={`mailto:${profile.email}`}
                                  style={{
                                    color: signature.text_color,
                                    textDecoration: 'none',
                                    display: 'inline-block',
                                    font: '12.3px/15.7px Poppins, sans-serif',
                                  }}
                                >
                                  {profile.email}
                                </a>
                              </td>
                            </tr>
                          )}
                          {signature.show_company_website &&
                            profile.company_website && (
                              <tr>
                                <td
                                  style={{
                                    margin: '0.1px',
                                    padding: '1px 0',
                                    font: '12.3px/15.7px Poppins, sans-serif',
                                    color: signature.text_color,
                                  }}
                                >
                                  <img
                                    src="https://assets.tapt.io/email/globe.png"
                                    alt="website"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      display: 'inline-block',
                                      position: 'relative',
                                      top: '-1px',
                                      marginRight: '8px',
                                    }}
                                  />
                                  <a
                                    href={profile.company_website}
                                    style={{
                                      color: signature.text_color,
                                      textDecoration: 'none',
                                      font: '12.3px/15.7px Poppins, sans-serif',
                                    }}
                                  >
                                    {profile.company_website.replace(
                                      /^https?:\/\//,
                                      '',
                                    )}
                                  </a>
                                </td>
                              </tr>
                            )}
                          {signature.show_company_phone &&
                            profile.company_phone && (
                              <tr>
                                <td
                                  style={{
                                    margin: '0.1px',
                                    padding: '1px 0',
                                    font: '12.3px/15.7px Poppins, sans-serif',
                                    color: signature.text_color,
                                  }}
                                >
                                  <img
                                    src="https://assets.tapt.io/email/phone.png"
                                    alt="website"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      display: 'inline-block',
                                      position: 'relative',
                                      top: '-1px',
                                      marginRight: '8px',
                                    }}
                                  />
                                  {profile.company_phone}
                                </td>
                              </tr>
                            )}
                          {signature.show_company_address && address && (
                            <tr>
                              <td
                                style={{
                                  margin: '0.1px',
                                  padding: '1px 0',
                                  font: '12.3px/15.7px Poppins, sans-serif',
                                  color: signature.text_color,
                                }}
                              >
                                <img
                                  src="https://assets.tapt.io/email/location-marker.png"
                                  alt="website"
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '-1px',
                                    marginRight: '8px',
                                  }}
                                />
                                {address}
                              </td>
                            </tr>
                          )}
                          {signature.show_social_links &&
                            profile.profile_social_links &&
                            profile.profile_social_links.length > 0 && (
                              <tr>
                                <td
                                  style={{
                                    margin: '0.1px',
                                    padding: '10px 0 0',
                                    font: '12.3px/15.7px Poppins, sans-serif',
                                    color: signature.text_color,
                                  }}
                                >
                                  <table
                                    cellPadding={0}
                                    style={{ borderCollapse: 'collapse' }}
                                  >
                                    <tbody>
                                      <tr>
                                        {profile.profile_social_links.map(
                                          (link, index) =>
                                            allowedSocialLinks.includes(
                                              link.type,
                                            ) && (
                                              <td
                                                key={index}
                                                style={{
                                                  margin: '0.1px',
                                                  padding: '0 5px 0 0',
                                                }}
                                              >
                                                <a
                                                  href={link.value}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <img
                                                    src={`https://assets.tapt.io/email/${link.type}.png`}
                                                    alt={link.type}
                                                    style={{
                                                      width: '20px',
                                                      height: '20px',
                                                    }}
                                                  />
                                                </a>
                                              </td>
                                            ),
                                        )}
                                        <td
                                          style={{
                                            margin: '0.1px',
                                            padding: '0 5px 0 0',
                                          }}
                                        >
                                          <a
                                            href={urlForCTA}
                                            style={{
                                              textDecoration: 'none',
                                              font: '12.3px/15.7px Poppins, sans-serif',
                                              verticalAlign: 'middle',
                                              padding: '5px',
                                              color: '#111827',
                                              backgroundColor: '#e1dcdc',
                                              borderRadius: '5px',
                                            }}
                                          >
                                            Add to Contacts
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          {signature.show_banner && (
            <tr>
              <td
                style={{
                  margin: '0.1px',
                  padding: '15px 0 0',
                  font: '12.3px/15.7px Poppins, sans-serif',
                  color: signature.text_color,
                }}
              >
                <img
                  src="https://img.mysignature.io/b/c/9/f/c9fa345e-8a74-564a-86c7-0cff86fe4e38.png"
                  alt="Banner"
                  style={{ width: '500px', borderRadius: '4px' }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table width="413" cellPadding="0" style={{ borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            {signature.show_qr_code && qrImageUrl && (
              <td
                style={{
                  margin: '0.1px',
                  padding: '15px 15px 0 0',
                  width: '124px',
                  verticalAlign: 'top',
                }}
              >
                <table
                  style={{
                    // width: '109px',
                    // height: '70px',
                    backgroundColor: '#e1dcdc',
                    borderRadius: '8px 0 8px 0',
                    borderCollapse: 'collapse',
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: '4px 4px 4px 8px',
                          verticalAlign: 'top',
                        }}
                      >
                        <img
                          src={qrImageUrl}
                          alt="QR Code"
                          style={{
                            display: 'block',
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                          }}
                        />
                      </td>
                      <td
                        style={{
                          padding: '5px 8px 4px 4px',
                          verticalAlign: 'top',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '9px',
                          fontWeight: '500',
                          lineHeight: '1.2',
                          color: '#111827',
                        }}
                      >
                        <div>Scan to</div>
                        <div>connect</div>
                        <TertiaryLogo
                          style={{
                            width: '25px',
                            height: '8px',
                            marginTop: '14px',
                            display: 'block',
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            )}
            <td
              style={{
                margin: '0.1px',
                padding: '15px 0 0',
                font: '12.1px/15.3px Poppins, sans-serif',
                color: '#404040',
                fontWeight: '400',
              }}
            >
              {signature.disclaimer_text}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
function addQueryParams(arg0: string, arg1: any) {
  throw new Error('Function not implemented.');
}
