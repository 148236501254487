import { useCallback, useState } from 'react';

import { Tab } from '@headlessui/react';

import adminAPI from '@/api/admin';
import ActivationKeysItem from '@/components/ActivationKeysItem';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Input from '@/components/Input';
import InputCheckbox from '@/components/InputCheckbox';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Modal from '@/components/Modals/Modal';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import TabList from '@/components/TabList';
import ToggleField from '@/components/ToggleField';
import MESSAGES from '@/constants/messages-en';
import { classNames } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import IActivationKey from '@/types/IActivationKey';
import { IListNew, IListPaging } from '@/types/IList';

export default function ActivationsKeysListPage(): JSX.Element {
  const { user } = useAuth();
  const [data, setData] = useState<IActivationKey[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [noOfKeys, setNoOfKeys] = useState<string>('');
  const [noOfProfiles, setNoOfProfiles] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [hideActivatedKeys, setHideActivatedKeys] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const tabs = [
    {
      name: 'Shopify Generated Keys',
      value: 'shopify_generated',
    },
    {
      name: 'Card Generated Keys',
      value: 'card_generated',
    },
    {
      name: 'Custom Keys',
      value: 'custom',
    },
  ];

  const [type, setType] = useState<string>('shopify_generated');

  const listActivationKeys = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = status,
      newSearch = search,
      newType = type,
    }: IListNew): Promise<{ data: IActivationKey[]; paging: IListPaging }> => {
      const res = await adminAPI.listCardActivations({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        type: newType,
        order: newOrder,
        search: newSearch,
        status: newStatus,
      });

      setData(res.data?.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setType(newType);
      setSort(newSort);
      setOrder(newOrder);
      setSearch(newSearch);

      return res.data;
    },
    [order, page, pageSize, sort, type, search, status],
  );

  function handleCreateCustomKey() {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .createActivationKey({
        count: Number(noOfKeys),
        card_activation: {
          email: user?.email,
          no_of_profiles: Number(noOfProfiles),
          type: 'custom',
        },
      })
      .then(() => setSuccess(MESSAGES.activationKey.create.success))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => {
        setNoOfKeys('');
        setNoOfProfiles('');
      })
      .then(() => listActivationKeys({ newType: 'custom' }))
      .finally(() => setIsOpen(false));
  }

  function handleCheckItem(keyID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(keyID)) {
      arr = arr.filter(e => e !== keyID);
    } else {
      arr = [...checkedItems, keyID];
    }
    setCheckedItems(arr);
  }

  function handleDelete(activationKeyID: number) {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .deleteActivationKey(activationKeyID)
      .then(() =>
        setSuccess(MESSAGES.activationKey.delete.success(activationKeyID)),
      )
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listActivationKeys({ newPage: 1 }))
      .finally(() => setIsOpen(false));
  }

  const exportCSV = useCallback(async () => {
    if (checkedItems) {
      const response = await adminAPI.exportKeysCSV(checkedItems);
      const blob = new Blob([response.data]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = 'keys.csv';
      a.click();
    }
  }, [checkedItems]);

  return (
    <Layout pageName="Activation Keys">
      <div className="md:pb-8 md:flex md:items-center md:justify-end md:-mt-20">
        <div className="mt-3 flex space-x-4 md:mt-0 md:ml-4">
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            buttonTitle="Create new custom key"
            dialogTitle={MESSAGES.activationKey.create.heading}
            successButtonText="Create custom key"
            onSuccess={() => handleCreateCustomKey()}
            isLoading={isLoading}
          >
            <div className="mt-6 space-y-4">
              <Input
                label="Number of keys"
                value={noOfKeys}
                onChange={setNoOfKeys}
                type="text"
              />
              <Input
                label="Number of profiles per key"
                value={noOfProfiles}
                onChange={setNoOfProfiles}
                type="text"
              />
            </div>
          </Modal>
        </div>
      </div>
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <Tab.Group
        onChange={index => {
          setPage(1);
          setError(false);
          setType(tabs[index].value);
          setSelectAll(false);
          setCheckedItems([]);
          listActivationKeys({ newType: tabs[index].value }).finally(() =>
            setIsLoading(false),
          );
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 w-full md:w-auto">
            <TabList tabs={tabs} value={type} />
          </div>
        </div>
        <Tab.Panels>
          {tabs.map(tab => (
            <Tab.Panel key={tab.name} className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 flex-shrink-0 sm:space-x-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
                  <ToggleField
                    label="Hide activated keys"
                    enabled={hideActivatedKeys}
                    setter={value => {
                      setHideActivatedKeys(value);
                      if (value) setStatus('not_activated');
                      else setStatus('');
                    }}
                  />
                  <Search
                    id={`ActivationKeysList-${page}-${pageSize}-${sort}-${order}-${type}-${search}-${status}`}
                    search={search}
                    setSearch={setSearch}
                    fetchQuery={searchQuery =>
                      listActivationKeys({ newSearch: searchQuery })
                    }
                  />
                  <Sort
                    id={`ActivationKeysList-${page}-${pageSize}-${sort}-${order}-${type}-${search}-${status}`}
                    options={[
                      {
                        sort: 'date',
                        order: 'desc',
                        label: 'Date created (Newest first)',
                      },
                      {
                        sort: 'date',
                        order: 'asc',
                        label: 'Date created (Oldest first)',
                      },
                    ]}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    fetchQuery={(sortQuery, orderQuery) =>
                      listActivationKeys({
                        newSort: sortQuery,
                        newOrder: orderQuery,
                      })
                    }
                  />
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                          <div className="lg:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                            <InputCheckbox
                              id="select-all"
                              label=""
                              value={selectAll}
                              onChange={value => {
                                setSelectAll(value);
                                if (value) {
                                  const arr = data.map(({ id }) => id);
                                  setCheckedItems(arr);
                                } else {
                                  setCheckedItems([]);
                                }
                              }}
                            />
                          </div>
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li
                              key="ActivationKeysListHeader"
                              className="hidden md:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 sm:px-6">
                                  <div className="w-7">
                                    <InputCheckbox
                                      id="select-all"
                                      label=""
                                      value={selectAll}
                                      onChange={value => {
                                        setSelectAll(value);
                                        if (value) {
                                          const arr = data.map(({ id }) => id);
                                          setCheckedItems(arr);
                                        } else {
                                          setCheckedItems([]);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div
                                      className={classNames(
                                        'min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-4',
                                        type === 'shopify_generated'
                                          ? 'xl:grid-cols-7'
                                          : 'xl:grid-cols-6',
                                      )}
                                    >
                                      <p className="col-span-4 md:col-span-1 text-sm font-medium text-gray-900">
                                        KEY
                                      </p>
                                      {type === 'card_generated' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          LINKED CARD ID
                                        </p>
                                      )}
                                      {type === 'shopify_generated' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          SHOPIFY ORDER
                                        </p>
                                      )}
                                      {type === 'shopify_generated' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          CUSTOMER EMAIL
                                        </p>
                                      )}
                                      <p className="xl:col-span-1 hidden xl:block text-sm font-medium text-gray-900">
                                        PROFILES
                                      </p>
                                      <p className="xl:col-span-1 hidden md:block text-sm font-medium text-gray-900">
                                        STATUS
                                      </p>
                                      <p className="xl:col-span-1 hidden md:block text-sm font-medium text-gray-900">
                                        ORGANISATION
                                      </p>
                                      <p className="mt-2 md:mt-0 hidden md:block xl:col-span-1 text-sm font-medium text-gray-900">
                                        CREATED
                                      </p>
                                      {type === 'custom' && <div />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            {data?.map((item: IActivationKey) => (
                              <ActivationKeysItem
                                key={`${item.status}-${item.id}`}
                                id={item.id}
                                activationKey={item.activation_key}
                                email={item.email}
                                card={item.linked_card}
                                cardID={item.linked_card?.id}
                                noOfProfiles={item.no_of_profiles}
                                orderNumber={item.order_number}
                                status={item.status}
                                type={item.type}
                                insertedAt={new Date(item.inserted_at)}
                                selected={checkedItems.includes(item.id)}
                                checkItem={() => handleCheckItem(item.id)}
                                handleDelete={() => handleDelete(item.id)}
                              />
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.activationKey.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.activationKey.list.empty.description}
                    </p>
                  </div>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Pagination
        id={`ActivationKeysList-${page}-${pageSize}-${sort}-${order}-${type}-${search}-${status}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listActivationKeys({
            newPage: pageQuery,
            newPageSize: pageSizeQuery,
          })
        }
        setIsLoading={setIsLoading}
      />
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>
      <Modal
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        dialogTitle={MESSAGES.activationKey.delete.headingMultiple}
        successButtonText="Delete selected keys"
        onSuccess={() => {
          setIsLoading(true);
          setSuccess('');
          setError(false);
          adminAPI
            .deleteActivationKeysShared({
              ids: checkedItems,
            })
            .then(() =>
              setSuccess(MESSAGES.activationKey.delete.successMultiple),
            )
            .catch(() => setError(true))
            .then(() => setIsLoading(false))
            .then(() => setCheckedItems([]))
            .then(() => listActivationKeys({ newPage: 1 }))
            .finally(() => setIsDeleteOpen(false));
        }}
        successButtonKind={BUTTON_KIND.RED}
        isLoading={isLoading}
      />
      <InfoPanelFooter
        className={checkedItems?.length > 1 ? '' : '!-bottom-28'}
      >
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center justify-end flex-nowrap space-x-4">
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export selected keys"
            onClick={() => exportCSV()}
          />
          {type === 'custom' && (
            <Button
              kind={BUTTON_KIND.RED}
              buttonText="Delete selected keys"
              onClick={() => setIsDeleteOpen(true)}
            />
          )}
        </div>
      </InfoPanelFooter>
    </Layout>
  );
}
