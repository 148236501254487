import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import designsAPI from '@/api/designs';
import organisationsAPI from '@/api/organisations';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import OrganisationAdminSettings from '@/containers/OrganisationAdminSettings';
import { fileHelper } from '@/helpers/file';
import IDesign from '@/types/IDesign';
import { IOrganisationUser } from '@/types/IOrganisation';

export default function ViewOrganisationAdminPage() {
  const { organisation_id } = useParams<{ organisation_id: string }>();
  const orgID = organisation_id ? parseInt(organisation_id) : undefined;

  const [page, setPage] = useState<number>(1);
  const [organisationName, setOrganisationName] = useState<string>('');
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);
  const [designFiles, setDesignFiles] = useState<IDesign[] | []>([]);
  const [designsSelected, setDesignsSelected] = useState<
    { id: number; value: IDesign }[]
  >([]);

  const showOrganisation = useCallback(() => {
    if (orgID === undefined) {
      return;
    }

    organisationsAPI
      .showOrganisation(orgID)
      .then(res => {
        setOrganisationName(res?.data?.data?.name);
      })
      .finally(() => {});
  }, [orgID, setOrganisationName]);

  const getOrganisationUsers = useCallback(() => {
    if (orgID === undefined) {
      return;
    }

    organisationsAPI
      .listOrganisationRoles({ orgID })
      .then(res => setOrgUsers(res.data?.data))
      .finally(() => {});
  }, [orgID, setOrgUsers]);

  useEffect(() => {
    showOrganisation();
    getOrganisationUsers();
  }, [showOrganisation, getOrganisationUsers]);

  const getDesignFiles = useCallback(
    async (newPage: number = page) => {
      if (orgID === undefined) {
        return;
      }

      const res = await designsAPI.listDesigns({
        orgID,
        page: newPage,
        pageSize: 6,
      });

      setDesignFiles(res.data?.data?.filter((item: IDesign) => item.id));

      setDesignsSelected([]);

      return res.data;
    },
    [orgID, page],
  );

  return (
    <Layout pageName="Organisation Settings">
      {orgID && (
        <OrganisationAdminSettings
          orgID={orgID}
          organisationName={organisationName}
          setOrganisationName={setOrganisationName}
          orgUsers={orgUsers}
          setOrgUsers={setOrgUsers}
          disabled={false}
          page={page}
          setPage={setPage}
          designFiles={designFiles}
          setDesignFiles={setDesignFiles}
          designsSelected={designsSelected}
          setDesignsSelected={setDesignsSelected}
        />
      )}

      <InfoPanelFooter
        className={`${designsSelected?.length <= 0 ? '!-bottom-28' : ''}`}
      >
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">
          selected
        </p>
        <div className="flex items-center flex-nowrap space-x-4">
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export selected files"
            onClick={() => {
              designsSelected?.length &&
                designsSelected.forEach((e: { id: number; value: IDesign }) => {
                  fileHelper.downloadFile(e.value.design_file);
                });
            }}
          />
          <Button
            kind={BUTTON_KIND.LIGHT_RED}
            buttonText="Delete card designs"
            onClick={() => {
              designsSelected?.length &&
                designsSelected.forEach(async e => {
                  const deleteRequests = [];
                  if (e.value.id && orgID) {
                    deleteRequests.push(
                      designsAPI.deleteDesign(orgID, e.value.id),
                    );
                  }
                  await Promise.all(deleteRequests);
                  getDesignFiles();
                });
            }}
          />
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">
            {designsSelected?.length || 0} selected
          </p>
        </div>
      </InfoPanelFooter>
    </Layout>
  );
}
