import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useBlocker, useNavigate } from 'react-router-dom';

import { EyeIcon } from '@heroicons/react/24/outline';

import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import { ProfilePreviewModal } from '@/components/ProfilePreview';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import UploadPhoto from '@/components/UploadPhoto';
import { COVER_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';

export function ProfileDesign() {
  // cannot use form libraries or react query due to legacy components
  const { orgID } = useAuth();
  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(isDirty);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [backgroundColour, setBackgroundColour] = useState('#0A0A0A');
  const [textColour, setTextColour] = useState('#FFFFFF');
  const [buttonBackgroundColour, setButtonBackgroundColour] =
    useState('#873CFF');
  const [buttonTextColour, setButtonTextColour] = useState('#FFFFFF');
  const [logoFile, setLogoFile] = useState<IFile | undefined>(undefined);

  const [organisationError, setOrganisationError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    getOrganisationSettings();
  }, []);

  const makeFormDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  async function getOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsLoading(true);

      const {
        data: { data: organisation },
      } = await organisationsAPI.showOrganisationSettings(orgID);

      if (organisation.company_logo) {
        setLogoFile(organisation.company_logo);
      }
      if (organisation.bg_color) {
        setBackgroundColour(organisation.bg_color);
      }
      if (organisation.text_color) {
        setTextColour(organisation.text_color);
      }
      if (organisation.button_bg_color) {
        setButtonBackgroundColour(organisation.button_bg_color);
      }
      if (organisation.button_text_color) {
        setButtonTextColour(organisation.button_text_color);
      }
    } catch {
      setOrganisationError(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOrganisationSettingsSave() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsSaving(true);
      setSuccessMessage(undefined);
      setOrganisationError(false);

      await organisationsAPI.updateOrganisationSettings(orgID, {
        organisation_setting: {
          bg_color: backgroundColour,
          text_color: textColour,
          button_bg_color: buttonBackgroundColour,
          button_text_color: buttonTextColour,
          company_logo_file_id: logoFile ? logoFile.id : null,
        },
      });

      setIsDirty(false);
      setSuccessMessage(MESSAGES.organisation.template);
    } catch {
      setOrganisationError(true);
    } finally {
      setIsSaving(false);
    }
  }

  const colorPickers = [
    {
      title: 'Page background colour',
      color: backgroundColour,
      setColor: setBackgroundColour,
    },
    {
      title: 'Text colour',
      color: textColour,
      setColor: setTextColour,
    },
    {
      title: 'Button background colour',
      color: buttonBackgroundColour,
      setColor: setButtonBackgroundColour,
    },
    {
      title: 'Button text colour',
      color: buttonTextColour,
      setColor: setButtonTextColour,
    },
  ];

  return (
    <div className="pt-8 pb-[33%] md:pb-[68px]" onFocusCapture={makeFormDirty}>
      {successMessage && (
        <div className="pb-8">
          <SuccessAlert message={successMessage} />
        </div>
      )}
      <InfoPanelContainer
        information="Customise the look of the profile pages of your cardholders. Changes will apply to all profile pages belonging to your organisation."
        title="Profile page appearance"
        footerContent={() => (
          <>
            <Button
              buttonText="Preview"
              kind={BUTTON_KIND.WHITE}
              icon={<EyeIcon strokeWidth={2} />}
              onClick={() => setIsPreviewOpen(true)}
            />
            <ProfilePreviewModal
              isOpen={isPreviewOpen}
              setIsOpen={setIsPreviewOpen}
              settings={{
                bgColor: backgroundColour,
                textColor: textColour,
                buttonBgColor: buttonBackgroundColour,
                buttonTextColor: buttonTextColour,
                companyLogo: logoFile,
              }}
            />
          </>
        )}
      >
        {organisationError && (
          <ErrorAlert message="Something went wrong. Please try again later" />
        )}
        <h4 className="text-xl leading-7 font-medium text-gray-900">
          Cover image
        </h4>
        <p className="text-sm leading-5 text-gray-500">
          Choose an image to display at the top of cardholder profile pages.
        </p>
        <div className="flex items-center mt-4 mb-0">
          {isLoading ? (
            <div className="w-full mb-6">
              <Skeleton height={175} />
            </div>
          ) : (
            <UploadPhoto
              title="Cover image"
              photo={logoFile}
              setPhoto={setLogoFile}
              size="large"
              aspectRatio={16 / 11}
              fileFormatMessage="Recommended dimensions 1024px x 704px"
              maxHeight={COVER_IMAGE.MAX_HEIGHT}
              maxWidth={COVER_IMAGE.MAX_WIDTH}
            />
          )}
        </div>
        <h4 className="text-xl leading-7 font-medium text-gray-900">Colours</h4>
        <p className="text-sm leading-5 text-gray-500">
          Create a custom theme for cardholder profile pages. Maintain good
          readability by ensuring there is sufficient contrast between text and
          background colours.
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 w-full max-w-2xl">
          {colorPickers.map((picker, index) => (
            <div key={index}>
              <span className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                {picker.title}
              </span>
              {isLoading ? (
                <Skeleton width={190} height={42} />
              ) : (
                <ColorPicker color={picker.color} setColor={picker.setColor} />
              )}
            </div>
          ))}
        </div>
      </InfoPanelContainer>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={() => navigate(-1)}
          />
          <Button
            buttonText="Save changes"
            className="flex-1 xl:flex-none"
            loading={isSaving}
            onClick={handleOrganisationSettingsSave}
          />
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={async () => {
            await handleOrganisationSettingsSave();
            blocker.proceed();
          }}
          cancel={blocker.proceed}
          reset={blocker.reset}
          isLoading={isSaving}
        />
      )}
    </div>
  );
}
