import { AxiosResponse } from 'axios';

import { SupportedQRCodeFormat } from '@/components/ProfilesListPage/QRCode';
import axiosApiService from '@/services/axiosApiService';
import IList, { IListPaging } from '@/types/IList';
import { IEmailSignature, IOrganisationSettings } from '@/types/IOrganisation';
import IProfile, { IProfileBase, IProfilePublic, IProfileSignature } from '@/types/IProfile';

import { BASE_URL } from '../config';

const profilesAPI = {
  listProfiles: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = '',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IProfileBase[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        status !== '' ? `&status=${status}` : ''
      }${search !== '' ? `&search=${search}` : ''}`,
    );
  },
  listProfilesInGroup: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = '',
    search = '',
    groupID = 0,
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IProfileBase[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        status !== '' ? `&status=${status}` : ''
      }${search !== '' ? `&search=${search}` : ''}${
        groupID !== 0 ? `&group_id=${groupID}` : ''
      }`,
    );
  },
  listProfilesInGroups: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
    groupIDs = [],
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IProfileBase[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }${groupIDs.length > 0 ? `&group_ids=${groupIDs.join(',')}` : ''}`,
    );
  },
  listProfilesNotInGroup: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = '',
    search = '',
    groupID = 0,
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IProfileBase[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        status !== '' ? `&status=${status}` : ''
      }${search !== '' ? `&search=${search}` : ''}${
        groupID !== null ? `&not_group_id=${groupID}` : ''
      }`,
    );
  },
  createProfile: (
    orgID: number,
    body: {
      profile: Partial<IProfile>;
    },
  ): Promise<AxiosResponse<{ data: IProfile }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/profiles`,
      body,
    );
  },
  updateProfile: ({
    orgID,
    profileID,
    body,
  }: {
    orgID: number;
    profileID: number;
    body: {
      email_profile_editing?: boolean;
      profile: Partial<IProfile>;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/profiles/${profileID}?ref=dashboard`,
      body,
    );
  },
  updateProfilesShared: ({
    orgID,
    body,
  }: {
    orgID: number;
    body: {
      ids: number[];
      profile: Partial<IProfile>;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/profiles/shared?ref=dashboard`,
      body,
    );
  },
  showProfile: (
    orgID: number,
    profileID: number,
  ): Promise<AxiosResponse<{ data: IProfile }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles/${profileID}`,
    );
  },
  showProfileWithHashID: (
    hashID: string,
  ): Promise<AxiosResponse<{ data: IProfilePublic }>> => {
    return axiosApiService.get(`${BASE_URL}/api/profiles/${hashID}`);
  },
  showProfilesShared: (
    orgID: number,
    ids: number[],
  ): Promise<AxiosResponse<{ data: IProfile }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles/shared_2`,
      {
        params: { ids },
      },
    );
  },
  updateProfilesSharedNew: ({
    orgID,
    body,
  }: {
    orgID: number;
    body: {
      ids: number[];
      email_profile_editing?: boolean;
      profile: Partial<IProfile>;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/profiles/shared_2?ref=dashboard`,
      body,
    );
  },
  updateProfileWithHashID: (
    hashID: string,
    body: {
      profile: Partial<IProfile>;
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/profiles/${hashID}?ref=dashboard`,
      body,
    );
  },
  deleteProfile: (
    orgID: number,
    profileID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisations/${orgID}/profiles/${profileID}`,
    );
  },
  resetProfile: (
    orgID: number,
    profileID: number,
  ): Promise<AxiosResponse<{ data: IProfile }>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/profiles/${profileID}/reset`,
    );
  },
  deleteProfiles: (
    orgID: number,
    profileIDs: number[],
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/profiles/delete_batch`,
      {
        profile_ids: profileIDs,
      },
    );
  },
  resetProfiles: (
    orgID: number,
    profileIDs: number[],
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/profiles/reset_batch`,
      {
        profile_ids: profileIDs,
      },
    );
  },
  importProfilesCSV: (
    orgID: number,
    formData: FormData,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/csv_import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  exportProfilesCSV: (orgID: number): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles/export_csv`,
    );
  },
  showOrganisationSettings: (
    hashID: string,
  ): Promise<AxiosResponse<{ data: IOrganisationSettings }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/profiles/${hashID}/organisation_settings`,
    );
  },
  shareDetails: (
    hashID: string,
    body: {
      contact: {
        company_name: string;
        email: string;
        first_name: string;
        job_title: string;
        last_name: string;
        phone_number: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/profiles/${hashID}/contacts`,
      body,
    );
  },
  viewSocialLink(hashID: string, event_type: string) {
    return axiosApiService.post(
      `${BASE_URL}/api/profiles/${hashID}/social_link`,
      { event_type: event_type || '' },
    );
  },
  inviteUsers(orgId: number, userIds: number[]) {
    return Promise.all(
      userIds.map(i =>
        axiosApiService.post(
          `${BASE_URL}/api/organisations/${orgId}/profiles/${i}/app_invite`,
          {},
        ),
      ),
    );
  },
  downloadQRCode(orgID: number, id: number, fileType: SupportedQRCodeFormat) {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/profiles/${id}/qr_code`,
      {
        params: {
          file_type: fileType,
        },
        responseType: 'arraybuffer',
      },
    );
  },
  downloadQRCodeBulk(
    orgID: number,
    { ids, fileType }: { ids: number[]; fileType: SupportedQRCodeFormat },
  ) {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/profiles/qr_codes`,
      {
        profile_ids: ids,
        file_type: fileType,
      },
      {
        responseType: 'arraybuffer',
      },
    )
  },
  getEmailSignature(hashID: string): Promise<AxiosResponse<{ data: { signature: IEmailSignature, profile: IProfileSignature } }>> {
    return axiosApiService.get(
      `${BASE_URL}/api/profiles/${hashID}/email_signature`,
    );
  },
};

export default profilesAPI;
