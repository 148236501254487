import Iframe from 'react-iframe';
import { useLocation, useNavigate } from 'react-router-dom';

import Button, { BUTTON_KIND } from '@/components/Button';
import Layout from '@/components/Layout';
import { buildNewUrl } from '@/helpers/urlHandler';
import useAuth from '@/hooks/useAuth';
import { css, cx } from '@emotion/css';

export const PAGE_NAME = 'group-preview';

function GroupPreviewPage() {
  const { orgID } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate(-1);
  }

  const desktopPreviewHeight = '787px';
  const desktopPreviewWidth = '375px';

  const phoneStyles = css`
    // TODO: Improvement - replace media query value with Tailwind config reference
    @media (min-width: 768px) {
      background: #e5e7eb;
      border-radius: 40px;
      width: ${desktopPreviewWidth};
      max-height: ${desktopPreviewHeight};
    }
  `;
  const innerPhoneStyles = css`
    // TODO: Improvement - replace media query value with Tailwind config reference
    @media (min-width: 768px) {
      border-radius: 33px;
    }
  `;
  const urlParams = buildNewUrl({
    ...location.state,
    organisation_id: `${orgID || ''}`,
  });

  const previewPageUrl = `/view-profile-sample${urlParams}`;

  return (
    <Layout pageName="Edit Group" showPageName={false}>
      <div className="md:flex justify-center absolute inset-0 bg-gray-50">
        <div className="flex flex-col h-full w-full">
          <div className="w-full mx-auto px-4 sm:px-4 lg:px-6 py-4 border-b bg-white md:mb-4">
            <Button
              buttonText="Exit Preview"
              kind={BUTTON_KIND.WHITE}
              onClick={() =>
                navigate(location.state?.fromPage || '/groups', {
                  state: {
                    bg_color: location.state?.bg_color,
                    text_color: location.state?.text_color,
                    button_bg_color: location.state?.button_bg_color,
                    button_text_color: location.state?.button_text_color,
                    company_logo_file_id: location.state?.company_logo_file_id,
                    fromPage: PAGE_NAME,
                    tab: 'group-template',
                  },
                })
              }
            />
          </div>
          <div
            className={cx(
              phoneStyles,
              'w-full h-full inline-flex md:p-px md:shadow-2xl flex-grow mx-auto',
            )}
          >
            <Iframe
              className={cx(
                innerPhoneStyles,
                'w-full h-full pointer-events-none',
              )}
              display="block"
              position="relative"
              url={previewPageUrl}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default GroupPreviewPage;
