import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IUser from '@/types/IUser';
import IOrganisation, {
  IOrganisationUser,
  IOrganisationSettings,
  IOrganisationGroup,
  IOrganisationEditor,
  IOrganisationInvite,
  IOrganisationUpdate,
  IEmailSignature,
} from '@/types/IOrganisation';
import IList, { IListPaging } from '@/types/IList';

const organisationsAPI = {
  showOrganisation: (
    orgID: number,
  ): Promise<AxiosResponse<{ data: IOrganisation }>> => {
    return axiosApiService.get(`${BASE_URL}/api/organisations/${orgID}`);
  },
  updateOrganisation: (
    orgID: number,
    body: {
      organisation: {
        name?: string;
        domain_name?: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/organisations/${orgID}`, body);
  },
  deleteOrganisation: (orgID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(`${BASE_URL}/api/organisations/${orgID}`);
  },
  listOrganisationRoles: ({
    orgID,
    page = 1,
    pageSize = 10,
  }: {
    orgID: number;
    page?: number;
    pageSize?: number;
  }): Promise<
    AxiosResponse<{ data: IOrganisationUser[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_roles?page_size=${pageSize}&page=${page}`,
    );
  },
  createOrganisationRole: (
    orgID: number,
    body: {
      organisation_role: {
        user_id: number;
        role: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/organisation_roles`,
      body,
    );
  },
  updateOrganisationRole: (
    orgID: number,
    roleID: number,
    body: {
      organisation_role: {
        role: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/organisation_roles/${roleID}`,
      body,
    );
  },
  showOrganisationRoles: (orgID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_roles`,
    );
  },
  showOrganisationRoleFromUserID: (
    orgID: number,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
    userID = -1,
  ): Promise<
    AxiosResponse<{ data: IOrganisationUser[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_roles?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }${userID !== -1 ? `&user_id=${userID}` : -1}`,
    );
  },
  deleteOrganisationRole: (
    orgID: number,
    roleID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisations/${orgID}/organisation_roles/${roleID}`,
    );
  },
  inviteUser: (
    orgID: number,
    body: {
      invite: {
        email: string;
        organisation_id: number;
        role: string;
        group_ids: number[];
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/invites`,
      body,
    );
  },
  updateInvite: (
    orgID: number,
    inviteID: number,
    group_ids: number[],
  ): Promise<AxiosResponse<IOrganisationInvite>> => {
    return axiosApiService.patch(
      `${BASE_URL}/api/organisations/${orgID}/invites/${inviteID}`,
      {
        invite: {
          group_ids,
        },
      },
    );
  },
  acceptUserInvite: (body: {
    invite_code: string;
    invite: {
      first_name: string;
      last_name: string;
      password: string;
      password_confirmation: string;
    };
    type: string;
  }): Promise<AxiosResponse<{ data: IUser }>> => {
    return axiosApiService.post(`${BASE_URL}/api/accept_invite`, body);
  },
  listInvites: ({
    orgID,
    page = 1,
    pageSize = 10,
  }: {
    orgID: number;
    page?: number;
    pageSize?: number;
  }): Promise<
    AxiosResponse<{ data: IOrganisationInvite[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/invites?page_size=${pageSize}&page=${page}`,
    );
  },
  deleteInvite: (
    orgID: number,
    inviteID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisations/${orgID}/invites/${inviteID}`,
    );
  },
  showOrganisationSettings: (
    orgID: number,
  ): Promise<AxiosResponse<{ data: IOrganisationSettings }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_settings`,
    );
  },
  showAdminOrganisationSettings: (
    orgID: number,
  ): Promise<AxiosResponse<{ data: IOrganisationSettings }>> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/organisation/${orgID}/organisation_settings`,
    );
  },
  updateOrganisationSettings: (
    orgID: number,
    body: {
      email_profile_editing?: boolean;
      organisation_setting: Partial<IOrganisationSettings>;
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/organisation_settings`,
      body,
    );
  },
  listOrganisationGroups: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IOrganisationGroup[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_groups?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  createOrganisationGroup: (
    orgID: number,
    body: {
      organisation_group: Partial<IOrganisationGroup>;
    },
  ): Promise<AxiosResponse<{ data: IOrganisationGroup }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/organisation_groups`,
      body,
    );
  },
  updateOrganisationGroup: (
    orgID: number,
    groupID: number,
    body: {
      organisation_group: Partial<IOrganisationGroup>;
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/organisation_groups/${groupID}`,
      body,
    );
  },
  showOrganisationGroup: (
    orgID: number,
    groupHash: string,
  ): Promise<AxiosResponse<{ data: IOrganisationGroup }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_groups/${groupHash}`,
    );
  },
  showAllOrganisationGroup: (
    orgID: number,
  ): Promise<AxiosResponse<{ data: IOrganisationGroup }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/organisation_groups`,
    );
  },
  deleteOrganisationGroup: (
    orgID: number,
    groupID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisations/${orgID}/organisation_groups/${groupID}`,
    );
  },
  createGroupEditor: (
    groupID: number,
    body: {
      editor: {
        role_id: number;
      };
    },
  ): Promise<AxiosResponse<{ data: IOrganisationEditor }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisation_groups/${groupID}/editors`,
      body,
    );
  },
  deleteGroupEditor: (
    roleID: number,
    groupID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisation_groups/${groupID}/editors/${roleID}`,
    );
  },

  updateOrganisationAdminSettings: (data: {
    organisationSettingID: number | undefined;
    body: any;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/admin/api/organisation/${data?.organisationSettingID}/organisation_settings`,
      data?.body,
    );
  },

  getOrganisationAdminSettings: (data: {
    orgID: number;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${data?.orgID}/organisation_settings`,
    );
  },

  getDesignFilesbyOrgId: (data: {
    orgID: number;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${data?.orgID}/designs_file`,
    );
  },
  listRecentUpdates: (
    orgID: number,
    groupIDs: number[] = [],
  ): Promise<AxiosResponse<{ data: IOrganisationUpdate }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/recent_updates${
        groupIDs.length > 0 ? `?group_ids=${groupIDs.join(',')}` : ''
      }`,
    );
  },

  getEmailSignature: (
    orgID: number
  ): Promise<AxiosResponse<{data: IEmailSignature}>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/email_signature`,
    );
  },

  updateEmailSignature: (
    orgID: number,
    body: any
  ): Promise<AxiosResponse<IEmailSignature>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/email_signature`,
      body,
    );
  },
};

export default organisationsAPI;
