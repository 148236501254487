import { ChangeEvent, useEffect, useState } from 'react';

import {
  ArrowDownTrayIcon as DownloadIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import designsAPI from '@/api/designs';
import filesAPI from '@/api/files';
import DesignTemplate from '@/assets/documents/Tapt Design Template.pdf';
import { MESSAGES } from '@/constants/messages-en';
import { classNames } from '@/helpers/strings';
import IDesign from '@/types/IDesign';
import IFile from '@/types/IFile';
import { Box } from '@material-ui/core';

import { CustomScroll } from '../../styleds/CustomScroll';
import { ErrorAlert, SuccessAlert } from '../Alert';
import Badge, { BADGE_KIND } from '../Badge';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '../Button';
import Spinner from '../Icons/Spinner';
import InfoPanelContainer from '../InfoPanelContainer';
import InfoPanelFooter from '../InfoPanelFooter';
import InputCheckbox from '../InputCheckbox';

interface ICardDesigns {
  orgID: number;
}

const CardDesigns: React.FC<ICardDesigns> = ({ orgID }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [designFiles, setDesignFiles] = useState<IDesign[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(false);

  function handleCheckItem(orderId: number) {
    let arr = checkedItems;
    if (checkedItems.includes(orderId)) {
      arr = arr.filter(e => e !== orderId);
    } else {
      arr = [...checkedItems, orderId];
    }
    setCheckedItems(arr);
  }
  useEffect(() => {
    designsAPI.showDesigns(orgID).then(res => {
      setDesignFiles(res.data.data);
      setCheckedItems([]);
    });
  }, [initial, isUploading]);

  const download = async (designFile: IFile) => {
    setIsDownloading(true);

    try {
      const response = await filesAPI.downloadFile(designFile.original_url);

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      a.download = designFile.name;
      a.click();

      setSuccess(
        `File ${designFile?.file?.file_name} downloaded successfully!`,
      );
    } catch (error) {
      setError(true);
    } finally {
      setIsDownloading(false);
    }
  };
  const handleDownloadDesignTemplate = () => {
    const link = document.createElement('a');
    link.href = DesignTemplate;
    link.download = 'design_template.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadNewDesignFile = (files: FileList | null) => {
    setIsUploading(true);
    const promises: Promise<void>[] = [];
    files &&
      Array.from(files).forEach(file => {
        const promise = filesAPI
          .createAndUploadFile(orgID, new File([file], file.name))
          .then(res => {
            designsAPI.createDesign({
              orgID,
              body: {
                design: {
                  organisation_id: orgID,
                  design_file_id: res.data.data.id,
                },
              },
            });
          });
        promises.push(promise);
      });
    Promise.all(promises).then(() => {
      setInitial(!initial);
      setIsUploading(false);
    });
  };

  const handleDeleteDesign = () => {
    Promise.all(
      checkedItems.map(designID => {
        return designsAPI.deleteDesign(orgID, designID);
      }),
    ).then(() => {
      setInitial(!initial);
      setCheckedItems([]);
    });
  };

  return (
    <>
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <InfoPanelContainer
        title="Card designs"
        information="Select a design template for you card/s."
        additionalContents={
          <p className="text-sm text-gray-500">
            You can select a previously uploaded design, or upload a new design
            for a fresh, new look!
          </p>
        }
      >
        <Box className="border border-gray-200 rounded-lg bg-white">
          <Box
            className="w-full flex justify-center items-center border-b border-gray-100"
            style={{ height: '32px' }}
          >
            <Box className="w-11 flex justify-center items-center ">
              <InputCheckbox
                id="select-all"
                label=""
                value={selectAll}
                labelClassName="ml-0"
                onChange={(value: boolean) => {
                  setSelectAll(value);
                  if (value) {
                    const arr = designFiles.map(item => item.id);
                    setCheckedItems(arr);
                  } else {
                    setCheckedItems([]);
                  }
                }}
              />
            </Box>
            <Box
              className="min-w-0 flex-1 grid grid-cols-12 text-sm leading-5 font-medium text-gray-900"
              style={{ marginRight: '6px' }}
            >
              <Box className="col-span-6 lg:col-span-4 xl:col-span-3 ml-3 flex justify-start">
                Order
              </Box>
              <Box className="col-span-6 ml-3 lg:col-span-4 xl:col-span-6 flex justify-start">
                File name
              </Box>
              <Box className="col-span-1 hidden lg:flex justify-center items-center">
                View
              </Box>
              <Box className="col-span-3 hidden lg:flex xl:col-span-2 justify-center items-center">
                Download
              </Box>
            </Box>
          </Box>
          <CustomScroll style={{ height: '318px', overflow: 'scroll' }}>
            {designFiles.length !== 0 ? (
              designFiles.map((item, index) => {
                return (
                  <Box
                    className="w-full flex border-b border-gray-100"
                    key={index}
                    sx={{ height: '53px' }}
                  >
                    <Box className=" w-11 flex justify-center items-center">
                      <InputCheckbox
                        id={`Checkbox-${item.id}}`}
                        labelClassName="ml-0"
                        label=""
                        value={checkedItems.includes(item.id)}
                        onChange={() => handleCheckItem(item.id)}
                      />
                    </Box>
                    <Box className="min-w-0 flex-1 grid grid-cols-12 text-sm leading-5 font-normal">
                      <Box className="col-span-6 lg:col-span-4 xl:col-span-3  flex justify-start items-center ml-2">
                        <Badge
                          text={
                            item.order
                              ? `Order #${item.order.order_number}`
                              : 'In progress'
                          }
                          kind={item.order ? BADGE_KIND.GRAY : BADGE_KIND.AMBER}
                        />
                      </Box>
                      <Box className="col-span-6 flex justify-start items-center ml-3 text-gray-500 lg:col-span-4 xl:col-span-6">
                        <p className="truncate">{item.design_file?.name}</p>
                      </Box>
                      <Box className="col-span-1  hidden  justify-center items-center lg:flex">
                        <EyeIcon
                          className="stroke-1.3 text-gray-500 group-hover:text-brand-400 cursor-pointer"
                          style={{ width: '24px', height: '24px' }}
                          onClick={() =>
                            window.open(
                              `${item.design_file?.original_url}`,
                              '_blank',
                            )
                          }
                        />
                      </Box>
                      <Box className="col-span-3 hidden justify-center items-center lg:flex xl:col-span-2 ">
                        {!isDownloading ? (
                          item.design_file && (
                            <DownloadIcon
                              className="cursor-pointer"
                              width="24"
                              height="24"
                              color="#9CA3AF"
                              onClick={() => {
                                item.design_file && download(item.design_file);
                              }}
                            />
                          )
                        ) : (
                          <Spinner />
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <span className="text-center appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-grey-500 flex items-center justify-center h-32">
                No matching items found
              </span>
            )}
          </CustomScroll>
        </Box>
        <Box className="flex items-center justify-end gap-3 mt-5">
          {isUploading ? (
            <Spinner />
          ) : (
            <>
              <label className="cursor-pointer">
                <span className=" appearance-none font-medium transform duration-300 flex flex-shrink-0 items-center border cursor-pointer justify-center text-xs leading-4 py-1.5 px-3 rounded text-gray-700 bg-white border-gray-300 disabled:font-normal disabled:bg-gray-300 disabled:text-gray-700 disabled:border-gray-300 disabled:cursor-not-allowed  focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2 hover:bg-gray-100 hover:text-gray-700 hover:no-underline">
                  Upload new file
                </span>
                <input
                  type="file"
                  className="hidden"
                  multiple
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleUploadNewDesignFile(e.target.files);
                  }}
                />
              </label>
            </>
          )}
          <Button
            buttonText="Download design template"
            onClick={handleDownloadDesignTemplate}
            kind={BUTTON_KIND.PRIMARY}
            size={BUTTON_SIZE.XSMALL}
          />
        </Box>
      </InfoPanelContainer>
      {checkedItems.length > 0 && (
        <InfoPanelFooter
          className="z-10"
          wrapClassName="
              w-full max-w-7xl md:px-8 mx-auto px-4 sm:px-6 md:px-8 flex-grow py-3.5
            "
        >
          <div className={classNames('flex items-center justify-end')}>
            <div className="flex items-center  justify-center gap-3">
              <Button
                buttonText="Delete card designs"
                size={BUTTON_SIZE.BASE}
                onClick={handleDeleteDesign}
                kind={BUTTON_KIND.WHITE}
                className="text-red-450"
              />
            </div>
          </div>
        </InfoPanelFooter>
      )}
    </>
  );
};

export default CardDesigns;
