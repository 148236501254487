import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import LayoutStandard from '@/components/LayoutStandard';
import { searchParamsQuery } from '@/helpers/urlHandler';
import useAuth from '@/hooks/useAuth';

export default function SsoConsumePage() {
  const { ssoLogin, user } = useAuth();
  const location = useLocation();
  const { access_token: accessToken } = searchParamsQuery(location.search);

  useEffect(() => {
    ssoLogin(accessToken);
  });

  if (user) {
    return <Navigate to="/" />;
  }

  if (!accessToken) {
    <Navigate to="/login" />;
  }

  return (
    <LayoutStandard heading="Log in to your Tapt account">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md text-center">
        Logging in...
      </div>
    </LayoutStandard>
  );
}
