import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { PhotoIcon } from '@heroicons/react/24/outline';

import filesAPI from '@/api/files';
import Spinner from '@/components/Icons/Spinner';
import ImageCropper from '@/components/ImageCropper';
import ModalStandalone from '@/components/Modals/ModalStandalone';
import { MAX_UPLOAD_FILE_SIZE_IN_BYTES } from '@/constants/files';
import { classNames } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';

import { ErrorAlert } from './Alert';
import Button, { BUTTON_KIND } from './Button';

interface IUploadPhoto {
  aspectRatio?: number;
  disabled?: boolean;
  loading?: boolean;
  photo?: IFile;
  setPhoto: React.Dispatch<React.SetStateAction<IFile | undefined>>;
  size?: string;
  title?: string;
  fileFormatMessage?: string;
  maxHeight: number;
  maxWidth: number;
}

export default function UploadPhoto({
  aspectRatio,
  disabled,
  loading,
  photo,
  setPhoto,
  size,
  title,
  fileFormatMessage,
  maxHeight,
  maxWidth,
}: IUploadPhoto) {
  const { orgID } = useAuth();

  const [uploadFileError, setUploadFileError] = useState<string | undefined>(
    undefined,
  );
  const [selectedFile, setSelectedFile] = useState('');
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isCropping, setIsCropping] = useState(false);

  const onFileSelectedCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // Callback from file input selector. send this to the image crop editor
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        if (file.size > MAX_UPLOAD_FILE_SIZE_IN_BYTES) {
          setUploadFileError('Max file size is 8MB');
          return;
        }
        const path = (window.URL || window.webkitURL).createObjectURL(file);
        if (path) {
          setSelectedFile(path);
          setUploadFileError(undefined);
        }
        setIsEditorOpen(true);
        e.target.value = '';
      }
    },
    [],
  );

  const onCropSubmit = useCallback(
    async (res: Blob) => {
      if (orgID === undefined) {
        return;
      }

      setIsCropping(true);
      setIsEditorOpen(false);

      try {
        const { data: file } = await filesAPI.createAndUploadFile(
          orgID,
          new File([res], 'image.png'),
        );

        setPhoto(file.data);
        setUploadFileError(undefined);
      } catch (err) {
        setUploadFileError('Upload file failed');
      }

      setSelectedFile('');
      setIsCropping(false);
    },
    [orgID, setPhoto],
  );

  const onEditorClose = useCallback(() => {
    setIsEditorOpen(false);
    setSelectedFile('');
  }, []);

  return (
    <>
      <div className="space-y-2">
        <div className="text-sm font-medium text-gray-900 py-2">
          {title || 'Photo'}
        </div>
        <div
          className={classNames(
            size === 'large'
              ? 'mt-1 flex flex-col xl:flex-row xl:items-center'
              : 'flex items-center',
          )}
        >
          {size === 'large' ? (
            <span className="inline-flex flex-shrink-0 items-center justify-center h-44 w-fit xl:w-64 rounded-md overflow-hidden">
              {photo?.small_url ? (
                <div className="border bg-gray-100">
                  <img src={photo?.small_url} alt="Profile" />
                </div>
              ) : (
                <div className="flex items-center justify-center h-44 w-64 rounded-md overflow-hidden bg-gray-50 border-2 border-dashed">
                  <PhotoIcon className="h-24 w-24 text-gray-300" />
                </div>
              )}
            </span>
          ) : (
            <span className="inline-flex w-24 h-24 rounded-full border border-dashed border-gray-300 bg-gray-50 overflow-hidden items-center justify-center flex-shrink-0">
              {photo?.small_url ? (
                <img src={photo?.small_url} alt="Profile" />
              ) : (
                <PhotoIcon className="w-10 rounded-full text-gray-300" />
              )}
            </span>
          )}
          {!disabled && (
            <div
              className={classNames(
                size === 'large'
                  ? 'mt-5 xl:mt-0 xl:ml-5 flex flex-col xl:self-end'
                  : 'ml-5 flex flex-col',
              )}
            >
              <div className="flex items-center justify-between xl:justify-start">
                <label
                  htmlFor={`photo-upload-${title}`}
                  className="cursor-pointer appearance-none rounded-md font-medium transform transition duration-300 flex items-center w-max border text-sm leading-5 py-2 px-4 text-gray-700 bg-white border-gray-300 hover:bg-gray-100 hover:no-underline focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2"
                >
                  <span>{photo?.small_url ? 'Change' : 'Upload a photo'}</span>
                  <input
                    id={`photo-upload-${title}`}
                    name="photo-upload"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={onFileSelectedCallback}
                    className="sr-only"
                    disabled={disabled}
                  />
                  {(loading || isCropping) && (
                    <Spinner className="ml-2 text-gray-700" />
                  )}
                </label>
                {photo && (
                  <button
                    type="button"
                    onClick={() => {
                      setPhoto(undefined);
                    }}
                    className="appearance-none text-sm leading-5 font-medium text-brand-500 ml-4"
                    disabled={loading}
                  >
                    Remove
                  </button>
                )}
              </div>
              {fileFormatMessage && (
                <p className="text-sm leading-5 text-gray-400 mt-3">
                  {fileFormatMessage}
                </p>
              )}
              {uploadFileError && (
                <p className="text-sm leading-5 text-red-600 mt-3">
                  {uploadFileError}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <ModalStandalone isOpen={isEditorOpen} onClose={onEditorClose}>
        <div className="p-6">
          <ImageCropper
            aspectRatio={aspectRatio}
            filePath={selectedFile}
            loading={isCropping}
            onClose={onEditorClose}
            onCropSubmit={onCropSubmit}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        </div>
      </ModalStandalone>
    </>
  );
}

type UploadPhotoWithoutPreviewProps = {
  title: string;
  photo: IFile | undefined;
  setPhoto: React.Dispatch<React.SetStateAction<IFile | undefined>>;
  dimensions: {
    width: number;
    height: number;
  };
};

export function UploadPhotoWithoutPreview({
  title,
  dimensions,
  photo,
  setPhoto,
}: UploadPhotoWithoutPreviewProps) {
  const { orgID } = useAuth();
  const inputRef = useRef<HTMLInputElement>(null);

  const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<string | undefined>(
    undefined,
  );
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files === null) {
      return;
    }

    try {
      if (uploadError) {
        setUploadError(undefined);
      }

      const file = e.target.files[0];
      if (file.size > MAX_UPLOAD_FILE_SIZE_IN_BYTES) {
        setUploadError('File size is too large. Max file size is 8MB.');
        return;
      }

      const path = (window.URL || window.webkitURL).createObjectURL(file);

      setSelectedFile(path);
      setIsCropperOpen(true);
    } catch (error: unknown) {
      setUploadError('Something went wrong. Please try again!');
    } finally {
      // clear the input value to allow selecting the same file again
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }

  async function uploadFile(blob: Blob) {
    if (orgID === undefined) {
      return;
    }

    setIsCropperOpen(false);

    try {
      setIsUploading(true);

      const { data: file } = await filesAPI.createAndUploadFile(
        orgID,
        new File([blob], 'image.png'),
      );

      setPhoto(file.data);
    } catch (error: unknown) {
      setUploadError('Something went wrong. Please try again!');
    } finally {
      setIsUploading(false);
    }
  }

  const closeCropper = () => {
    setIsCropperOpen(false);
    setSelectedFile(undefined);
  };

  return (
    <div className="space-y-2">
      {uploadError && <ErrorAlert message={uploadError} />}
      <div className="flex justify-between space-y-2">
        <div className="space-y-2">
          <span className="text-sm font-medium">{title}</span>
          <div className="flex flex-col text-gray-500 text-sm">
            <span>
              Recommended dimensions: {dimensions.width}px x {dimensions.height}
              px
            </span>
            <span>Max file size 8MB (.jpeg or .png only)</span>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          {photo && (
            <div
              className="text-sm font-medium text-brand-500"
              onClick={() => setPhoto(undefined)}
            >
              Remove
            </div>
          )}
          <Button
            buttonText="Upload"
            kind={BUTTON_KIND.WHITE}
            onClick={() => inputRef.current?.click()}
            loading={isUploading}
          />
          <input
            ref={inputRef}
            id={`file-upload-${title}`}
            type="file"
            accept="image/png, image/jpeg"
            className="hidden"
            onChange={onFileSelected}
          />
          {selectedFile && (
            <ModalStandalone isOpen={isCropperOpen} onClose={closeCropper}>
              <div className="p-6">
                <ImageCropper
                  filePath={selectedFile}
                  maxHeight={dimensions.height}
                  maxWidth={dimensions.width}
                  onClose={closeCropper}
                  onCropSubmit={uploadFile}
                  aspectRatio={dimensions.width / dimensions.height}
                  loading={false}
                />
              </div>
            </ModalStandalone>
          )}
        </div>
      </div>
    </div>
  );
}
