import Modal from './Modals/Modal';

type UnsavedChangesModalProps = {
  proceed: () => void;
  reset: () => void;
  cancel: () => void;
  isLoading?: boolean;
};

export function UnsavedChangesModal({
  proceed,
  cancel,
  reset,
  isLoading = false,
}: UnsavedChangesModalProps) {
  return (
    <Modal
      isOpen
      dialogTitle="You have unsaved changes. Please save your work to avoid losing it."
      successButtonText="Save changes"
      cancelButtonText="Discard changes"
      onCancel={cancel}
      onClose={reset}
      onSuccess={proceed}
      isLoading={isLoading}
    />
  );
}
