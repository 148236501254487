import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tab } from '@headlessui/react';

import adminAPI from '@/api/admin';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import { BADGE_KIND, BadgeKind } from '@/components/Badge';
import Button, { BUTTON_KIND } from '@/components/Button';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import InputCheckbox from '@/components/InputCheckbox';
import Layout from '@/components/Layout';
import Modal from '@/components/Modals/Modal';
import { AllProductsTab } from '@/components/ProductsAdminPage/AllProductsTab';
import { CARD_STATUS } from '@/components/ProductsAdminPage/BaseTab';
import { ConnectedTab } from '@/components/ProductsAdminPage/ConnectedTab';
import { InProgressTab } from '@/components/ProductsAdminPage/InProgressTab';
import { UnconnectedTab } from '@/components/ProductsAdminPage/UnconnectedTab';
import { UnprogrammedTab } from '@/components/ProductsAdminPage/UnprogrammedTab';
import CreateProductsModal from '@/components/ProductsPage/CreateProductsModal';
import TabList from '@/components/TabList';
import MESSAGES from '@/constants/messages-en';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import { ICardAdmin } from '@/types/ICard';
import { IListNew, IListPaging } from '@/types/IList';
import SubscriptionStatus from '@/types/SubscriptionStatus';

type Tab = {
  name: string;
  value: CARD_STATUS;
  color?: BadgeKind;
};

const tabs: Tab[] = [
  {
    name: 'Unprogrammed',
    value: CARD_STATUS.UNPROGRAMMED,
    color: BADGE_KIND.RED,
  },
  {
    name: 'Unconnected',
    value: CARD_STATUS.PROGRAMMED,
    color: BADGE_KIND.PINK,
  },
  {
    name: 'In Progress',
    value: CARD_STATUS.PENDING_TO_PRINT,
    color: BADGE_KIND.AMBER,
  },
  {
    name: 'Connected',
    value: CARD_STATUS.CONNECTED,
    color: BADGE_KIND.EMERALD,
  },
  {
    name: 'All Products',
    value: CARD_STATUS.ALL,
  },
];

export const cardIsDeletable: (status: CARD_STATUS) => boolean = status => {
  const deletableStatuses = [
    CARD_STATUS.UNPROGRAMMED,
    CARD_STATUS.PROGRAMMED,
    CARD_STATUS.PENDING_TO_PRINT,
  ];

  return deletableStatuses.includes(status);
};

function ProductsListAdminPage() {
  const [searchParams] = useSearchParams();
  const scope = searchParams.get('scope');
  const uOrgID = searchParams.get('uOrgID');
  const uPlatformSubscriptionStatus = searchParams.get(
    'uPlatformSubscriptionStatus',
  );

  const { setScopeAndOrg } = useAuth();
  const { selectCards, cardStatus, selectCardStatus } = useAppState();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [data, setData] = useState<ICardAdmin[]>();
  const [unprogrammedCount, setUnprogrammedCount] = useState<number>();
  const [programmedCount, setProgrammedCount] = useState<number>();
  const [inProgressCount, setInProgressCount] = useState<number>();
  const [connectedCount, setConnectedCount] = useState<number>();
  const [allCount, setAllCount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search] = useState('');
  const [isBulkUpdateOpen, setIsBulkUpdateOpen] = useState(false);
  const [importCSV, setImportCSV] = useState<File>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(tabs[cardStatus].value);
  const [deleteIsAcknowledged, setDeleteIsAcknowledged] = useState(false);

  const setCount = (status: string, count: number) => {
    switch (status) {
      case 'unprogrammed':
        setUnprogrammedCount(count);
        break;
      case 'programmed':
        setProgrammedCount(count);
        break;
      case 'pending_to_print':
        setInProgressCount(count);
        break;
      case 'connected':
        setConnectedCount(count);
        break;
      case 'all':
        setAllCount(count);
        break;
      default:
        break;
    }
  };

  const getCount = (status: string) => {
    return {
      unprogrammed: unprogrammedCount,
      programmed: programmedCount,
      pending_to_print: inProgressCount,
      connected: connectedCount,
      all: allCount,
    }[status];
  };

  const listCards = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = status,
      newSearch = search,
      initial = false,
    }: IListNew): Promise<{ data: ICardAdmin[]; paging: IListPaging }> => {
      const res = await adminAPI.listCards({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setData(res.data.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      setCount(newStatus, res.data.paging.total_entries);

      return res.data;
    },
    [order, page, pageSize, search, sort, status],
  );

  function updateCardsShared(newStatus: string, profileID?: number | null) {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .updateCardsShared({
        ids: checkedItems,
        card: {
          status: newStatus,
          ...(profileID !== undefined
            ? {
                profile_id: profileID,
              }
            : {}),
        },
      })
      .then(() => setSuccess(MESSAGES.card.update.successMultiple))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }))
      .then(() => listCards({ newStatus, initial: true }));
  }

  const exportCSV = useCallback(async () => {
    if (checkedItems) {
      const response = await adminAPI.exportCardsCSV(checkedItems);
      const blob = new Blob([response.data]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = 'admin_products.csv';
      a.click();
    }
  }, [checkedItems]);

  const handleBulkUpdate = useCallback(() => {
    if (importCSV) {
      setIsLoading(true);
      setSuccess('');
      setError(false);
      const formData = new FormData();
      formData.append('file', importCSV);
      formData.append('type', 'card');
      adminAPI
        .importCardsCSV(formData)
        .then(() => setSuccess(MESSAGES.card.import.success))
        .catch(() => setError(true))
        .then(() => setIsLoading(false))
        .then(() => listCards({ newPage: 1 }))
        .finally(() => setIsBulkUpdateOpen(false));
    }
  }, [importCSV, listCards]);

  useEffect(() => {
    if (uOrgID && scope === 'user') {
      const uUserRole = 'org_admin';
      const uFeatures = ['saas'];

      if (uPlatformSubscriptionStatus) {
        setScopeAndOrg(
          scope,
          Number(uOrgID),
          true,
          uUserRole,
          uFeatures,
          null,
          uPlatformSubscriptionStatus,
        );
      } else {
        setScopeAndOrg(
          scope,
          Number(uOrgID),
          true,
          uUserRole,
          uFeatures,
          null,
          SubscriptionStatus.FREE,
        );
      }
    }

    if (initialLoading) {
      tabs.map(async item => {
        await listCards({ newStatus: item.value, initial: true }).then(
          ({ paging }) => {
            if (paging) {
              setCount(item.value, paging.total_entries);
            }
          },
        );
      });
      setInitialLoading(false);
    }
  }, [
    initialLoading,
    listCards,
    history,
    scope,
    setScopeAndOrg,
    uOrgID,
    uPlatformSubscriptionStatus,
  ]);

  return (
    <Layout pageName="Products">
      <div className="md:pb-8 md:flex md:items-center md:justify-end md:-mt-20">
        <div className="mt-3 flex space-x-4 md:mt-0 md:ml-4">
          <Modal
            isOpen={isBulkUpdateOpen}
            setIsOpen={setIsBulkUpdateOpen}
            buttonTitle="Bulk Update"
            dialogTitle={MESSAGES.card.import.heading}
            dialogDescription={MESSAGES.card.import.description}
            successButtonText="Import"
            onSuccess={() => handleBulkUpdate()}
            isLoading={isLoading}
          >
            <div className="mt-6 space-y-4">
              <div className="border rounded-md px-4 mt-6 flex flex-row items-center justify-between">
                <FileUploadButtonComponent
                  filename={importCSV?.name}
                  fileFormat=".csv"
                  fileFormatMessage="(.csv only)"
                  onFileSelected={file => setImportCSV(file)}
                  loading={isLoading}
                />
                {importCSV && (
                  <button
                    type="button"
                    onClick={() => setImportCSV(undefined)}
                    className="appearance-none text-brand-500 text-sm font-medium"
                  >
                    Remove link
                  </button>
                )}
              </div>
            </div>
          </Modal>
          <CreateProductsModal
            onRequest={() => {
              setSuccess('');
              setError(false);
            }}
            onSuccess={() => {
              setSuccess(MESSAGES.card.create.success);
              listCards({ newPage: 1 });
            }}
            onError={() => {
              setError(true);
            }}
          />
        </div>
      </div>
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <Tab.Group
        defaultIndex={cardStatus}
        onChange={index => {
          setPage(1);
          setError(false);
          setSuccess('');
          setStatus(tabs[index].value);
          selectCardStatus(index);
          setSelectAll(false);
          setCheckedItems([]);
          selectCards([]);
          listCards({ newStatus: tabs[index].value }).finally(() => {});
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 w-full md:w-auto">
            <TabList
              tabs={tabs.map(item => {
                return {
                  ...item,
                  count: getCount(item.value),
                };
              })}
              value={status}
            />
          </div>
        </div>
        <Tab.Panels>
          <UnprogrammedTab
            setError={setError}
            setSuccess={setSuccess}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
          <UnconnectedTab
            setError={setError}
            setSuccess={setSuccess}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
          <InProgressTab
            setError={setError}
            setSuccess={setSuccess}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
          <ConnectedTab
            setError={setError}
            setSuccess={setSuccess}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
          <AllProductsTab
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            setSuccess={setSuccess}
            setError={setError}
          />
        </Tab.Panels>
      </Tab.Group>
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>
      <Modal
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        dialogTitle={
          <>
            <span className="font-semibold">Warning:</span> Deleting these
            products and profiles is permanent.
          </>
        }
        dialogDescription={
          <>
            <div>
              By proceeding, you acknowledge that you will permanently delete
              this product.
            </div>
            <div className="flex mt-8">
              <InputCheckbox
                id="delete-acknowledged"
                onChange={() => setDeleteIsAcknowledged(!deleteIsAcknowledged)}
                value={deleteIsAcknowledged}
                label="I am sure I want to delete."
                labelClassName="text-gray-700 font-medium ml-3"
              />
            </div>
          </>
        }
        successButtonText="Delete"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          setIsLoading(true);
          setSuccess('');
          setError(false);
          adminAPI
            .deleteCardsShared({
              ids: checkedItems,
            })
            .then(() => setSuccess(MESSAGES.card.delete.successMultiple))
            .catch(() => setError(true))
            .then(() => setIsLoading(false))
            .then(() => setCheckedItems([]))
            .then(() => listCards({ newPage: 1 }))
            .finally(() => setIsDeleteOpen(false));
        }}
        isLoading={isLoading}
        isDisabled={!deleteIsAcknowledged}
      />
      <InfoPanelFooter
        className={checkedItems?.length > 1 ? '' : '!-bottom-28'}
        childrenClassName="px-4 py-3.5 md:px-8 flex-grow"
      >
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center flex-nowrap justify-end space-x-4">
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
          {cardIsDeletable(status) && (
            <Button
              kind={BUTTON_KIND.RED}
              buttonText="Delete products"
              onClick={() => setIsDeleteOpen(true)}
            />
          )}
          {status === 'unprogrammed' && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Mark as unconnected"
              onClick={() => updateCardsShared('programmed')}
            />
          )}
          {status === 'programmed' && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Mark as unprogrammed"
              onClick={() => updateCardsShared('unprogrammed')}
            />
          )}
          {status === 'connected' && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Unlink selected products"
              onClick={() => updateCardsShared('programmed', null)}
            />
          )}
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export selected products"
            onClick={() => {
              setIsLoading(true);
              setSuccess('');
              setError(false);
              exportCSV()
                .then(() => setSuccess(MESSAGES.card.export.success))
                .catch(() => setError(true))
                .then(() => setCheckedItems([]))
                .then(() => listCards({ newPage: 1 }))
                .finally(() => setIsLoading(false));
            }}
          />
        </div>
      </InfoPanelFooter>
    </Layout>
  );
}

export default ProductsListAdminPage;
