import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useBlocker, useNavigate } from 'react-router-dom';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

import organisationsAPI from '@/api/organisations';
import DefaultProfilePicture from '@/assets/images/DefaultProfilePicture.png';
import QRCode from '@/assets/images/qr-code.png';
import { ReactComponent as TaptLogo } from '@/assets/svg/logos/tertiary.svg';
import { SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Modal from '@/components/Modals/Modal';
import { UploadPhotoWithoutPreview } from '@/components/UploadPhoto';
import { isDarkColor } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';

import ColorPicker from '../ColorPicker';
import { UnsavedChangesModal } from '../UnsavedChangesPrompt';

export function DigitalWallet() {
  const { orgID } = useAuth();
  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(isDirty);

  const { data: organisation } = useQuery(
    ['showOrganisation', orgID],
    showOrganisation,
    {
      enabled: orgID !== undefined,
    },
  );
  const { data: organisationSettings } = useQuery(
    ['showOrganisationSettings', orgID],
    showOrganisationSettings,
    {
      enabled: orgID !== undefined,
    },
  );

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );
  const [backgroundColorApple, setBackgroundColorApple] = useState('#FFFFFF');
  const [textColorApple, setTextColorApple] = useState('#000000');

  const [bannerImage, setBannerImage] = useState<IFile | undefined>(undefined);
  const [companyLogoGoogle, setCompanyLogoGoogle] = useState<IFile | undefined>(
    undefined,
  );
  const [companyLogoApple, setCompanyLogoApple] = useState<IFile | undefined>(
    undefined,
  );

  const [backgroundColorGoogle, setBackgroundColorGoogle] = useState('#FFFFFF');
  const textColorGoogle = isDarkColor(backgroundColorGoogle)
    ? '#FFFFFF'
    : '#000000';

  useEffect(() => {
    if (organisationSettings) {
      setBackgroundColorApple(organisationSettings.data.bg_color);
      setTextColorApple(organisationSettings.data.text_color);
      setBackgroundColorGoogle(organisationSettings.data.bg_color);
    }
  }, [organisationSettings]);

  async function showOrganisation() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.showOrganisation(orgID);
    return res.data;
  }

  async function showOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.showOrganisationSettings(orgID);
    return res.data;
  }

  const makeFormDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  return (
    <div className="pt-8 pb-[33%] md:pb-[68px]" onFocusCapture={makeFormDirty}>
      {successMessage && <SuccessAlert message={successMessage} />}
      <div className="flex flex-col xl:flex-row justify-between gap-6">
        <div className="flex-1 flex flex-col border border-gray-300 rounded-md p-4 bg-white h-fit">
          <span className="font-medium text-xl text-gray-900">
            Apple Wallet Customisation
          </span>
          <InfoPanelDivider />
          <div className="flex flex-col 2xl:flex-row gap-y-8 justify-between">
            <div className="flex gap-8">
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Pass background colour
                </span>
                {organisationSettings === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={backgroundColorApple}
                    setColor={setBackgroundColorApple}
                  />
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Text colour
                </span>
                {organisationSettings === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={textColorApple}
                    setColor={setTextColorApple}
                  />
                )}
              </div>
            </div>
            <Button
              buttonText="Reset colours"
              className="self-end"
              kind={BUTTON_KIND.WHITE}
              onClick={() => {
                if (organisationSettings) {
                  setBackgroundColorApple(organisationSettings.data.bg_color);
                  setTextColorApple(organisationSettings.data.text_color);
                }
              }}
            />
          </div>
          <InfoPanelDivider />
          <UploadPhotoWithoutPreview
            title="Company mark logo/icon"
            photo={companyLogoApple}
            setPhoto={setCompanyLogoApple}
            dimensions={{
              width: 50,
              height: 50,
            }}
          />
        </div>
        <div className="flex items-center justify-center border border-gray-300 rounded-md bg-white p-6 h-fit">
          {organisation ? (
            <AppleGenericPass
              payload={{
                title: 'Company name',
                header: 'John Doe',
                subheading: 'Marketing Manager',
                logo: companyLogoApple ? companyLogoApple.small_url : null,
                backgroundColor: backgroundColorApple,
                textColor: textColorApple,
              }}
            />
          ) : (
            <Skeleton width={330} height={440} />
          )}
        </div>
      </div>
      <InfoPanelDivider />
      <div className="flex flex-col xl:flex-row justify-between gap-6">
        <div className="flex-1 flex flex-col rounded-md border border-gray-300 bg-white p-6 h-fit">
          <span className="font-medium text-xl text-gray-900">
            Google Wallet Customisation
          </span>
          <InfoPanelDivider />
          <div className="flex flex-col xl:flex-row justify-between gap-y-8">
            <div className="flex gap-6">
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Pass background colour
                </span>
                {organisationSettings === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={backgroundColorGoogle}
                    setColor={setBackgroundColorGoogle}
                  />
                )}
              </div>
            </div>
            <Button
              buttonText="Reset colours"
              className="self-end"
              kind={BUTTON_KIND.WHITE}
              onClick={() => {
                if (organisationSettings) {
                  setBackgroundColorGoogle(organisationSettings.data.bg_color);
                }
              }}
              loading={organisationSettings === undefined}
            />
          </div>
          <InfoPanelDivider />
          <UploadPhotoWithoutPreview
            title="Banner image"
            photo={bannerImage}
            setPhoto={setBannerImage}
            dimensions={{
              width: 1032,
              height: 336,
            }}
          />
          <InfoPanelDivider />
          <UploadPhotoWithoutPreview
            title="Company logo mark/icon"
            photo={companyLogoGoogle}
            setPhoto={setCompanyLogoGoogle}
            dimensions={{
              width: 430,
              height: 430,
            }}
          />
        </div>
        <div className="flex items-center justify-center border border-gray-300 rounded-md bg-white p-6 h-fit">
          {organisation ? (
            <GoogleGenericPass
              payload={{
                title: organisation.data.name,
                subheading: 'Marketing Manager',
                header: 'John Doe',
                heroImage: bannerImage ? bannerImage.small_url : null,
                logo: companyLogoGoogle ? companyLogoGoogle.small_url : null,
                backgroundColor: backgroundColorGoogle,
              }}
            />
          ) : (
            <Skeleton width={270} className="h-full" />
          )}
        </div>
      </div>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={() => navigate(-1)}
          />
          <Button buttonText="Save changes" className="flex-1 xl:flex-none" />
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={blocker.proceed}
          reset={blocker.reset}
          cancel={blocker.proceed}
        />
      )}
    </div>
  );
}

type GoogleWalletPassProps = {
  payload: GoogleWalletPayload;
};

type GoogleWalletPayload = {
  logo: string | null;
  title: string;
  backgroundColor: string;
  subheading?: string;
  header?: string;
  heroImage: string | null;
};

// reference: https://developers.google.com/wallet/generic/resources/pass-builder
function GoogleGenericPass({ payload }: GoogleWalletPassProps) {
  const { backgroundColor, title, subheading, header, logo, heroImage } =
    payload;

  const textColor = isDarkColor(backgroundColor) ? 'white' : 'black';

  return (
    <div
      className="w-[300px] shadow-xl rounded-md overflow-hidden"
      style={{ color: textColor, backgroundColor }}
    >
      {/* title bar */}
      <div className="flex items-center space-x-3 px-3 pt-3">
        {/* logo */}
        <span className="w-6 h-6 rounded-full bg-white flex-shrink-0 inline-flex items-center justify-center overflow-hidden">
          {logo ? <img src={logo} /> : <TaptLogo width={20} height={20} />}
        </span>
        {/* title */}
        <span className="text-sm font-medium">{title}</span>
      </div>
      {/* divider */}
      <div className="h-[1px]" />
      {/* spacer */}
      <div className="h-[10px]" />
      {/* header */}
      <div className="flex flex-col px-3">
        {subheading && <span className="text-sm">{subheading}</span>}
        {header && <span className="text-xl">{header}</span>}
      </div>

      {/* spacer */}
      <div className="h-1.5" />
      {/* spacer */}
      <div className="h-[10px]" />
      {/* qr code */}
      <div className="py-[5px]">
        <div className="p-[14px] bg-white rounded-xl place-self-center">
          <img src={QRCode} className="w-28 h-28" alt="Profile QR Code" />
        </div>
      </div>
      {/* alternate text */}
      <div className="text-sm py-0.5 text-center">Powered by Tapt</div>
      {/* spacer */}
      <div className="h-[10px]" />
      {/* hero image */}
      {heroImage && (
        <div className="h-[88px]">
          <img src={heroImage} className="w-full h-full object-cover" />
        </div>
      )}
    </div>
  );
}

type AppleWalletPassProps = {
  payload: AppleWalletPayload;
};

type AppleWalletPayload = Omit<GoogleWalletPayload, 'heroImage'> & {
  textColor: string;
};

// references https://developer.apple.com/design/human-interface-guidelines/wallet#Generic-passes
function AppleGenericPass({ payload }: AppleWalletPassProps) {
  const {
    backgroundColor,
    logo,
    textColor,
    header: primaryField,
    subheading: secondaryField,
    title: logoText,
  } = payload;

  return (
    <div
      className="h-[420px] w-[300px] flex flex-col justify-between rounded-md shadow-xl"
      style={{ backgroundColor, color: textColor }}
    >
      <div className="space-y-6">
        <div className="flex items-center p-4 space-x-4">
          {/* logo */}
          {logo ? (
            <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
              <img src={logo} width={30} height={30} />
            </div>
          ) : (
            <TaptLogo width={30} height={30} />
          )}
          {logoText && <div className="text-sm font-medium">{logoText}</div>}
        </div>

        <div className="flex justify-between px-4">
          <div className="space-y-20">
            {primaryField && <div className="font-medium">{primaryField}</div>}
            {secondaryField && (
              <div className="text-sm font-light">{secondaryField}</div>
            )}
          </div>
          <div className="w-20 h-20 rounded-full overflow-hidden">
            <img
              src={DefaultProfilePicture}
              className="w-20 h-20"
              alt="Profile photo"
            />
          </div>
        </div>
      </div>

      <div className="pb-3">
        <div className="p-2 rounded-md bg-white place-self-center">
          <img src={QRCode} className="w-28 h-28" alt="Profile QR Code" />
        </div>
      </div>
    </div>
  );
}

type SendPassModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedProfiles: number[];
};

export function SendPassModal({
  isOpen,
  setIsOpen,
  checkedProfiles,
}: SendPassModalProps) {
  function sendPass() {
    console.log({ checkedProfiles });
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Share digital wallet pass with profile holders via email"
      dialogDescription="An email will be sent to the selected profile holders inviting them to add their Tapt profile to their digital wallet (either Apple Wallet or Google Wallet)."
      successButtonText="Send pass"
      onSuccess={sendPass}
    />
  );
}

export function DigitalWalletBanner() {
  return (
    <div className="bg-amber-50 text-sm flex text-amber-800 items-center justify-center font-medium gap-x-2 border border-amber-300 rounded-md p-2 xl:w-max self-end">
      <InformationCircleIcon className="w-5 h-5 text-amber-500" />
      All profiles (including those in groups) will have the same digital wallet
      design.
    </div>
  );
}
