import { format, parseISO } from 'date-fns';
import { filesize } from 'filesize';

import IFile from '@/types/IFile';
import { Box } from '@material-ui/core';

interface IClientComment {
  participant_name: string;
  comment_time: string;
  comment: string;
  comment_file: [] | IFile[];
}

export default function ClientComment({
  participant_name,
  comment_time,
  comment,
  comment_file,
}: IClientComment) {
  const parsedDate = parseISO(comment_time);

  return (
    <Box className="w-4/5 flex justify-start items-end">
      <Box>
        <Box className="flex py-3 gap-6 w-full font-poppins text-sm font-medium text-gray-400 items-center">
          <p className="flex-1 text-brand-500">Client</p>
          <p>
            {format(parsedDate, 'hh:mm')} {format(parsedDate, 'DD/MM/YY')}
          </p>
          <p>{participant_name}</p>
        </Box>
        <Box className="border rounded-lg px-4 pt-3 pb-5 bg-brand-50">
          <pre className="break-words text-sm leading-5 font-normal text-gray-900 whitespace-pre-wrap font-poppins">
            {comment}
          </pre>
          {comment_file.length > 0 && (
            <>
              <Box className="hidden sm:block" aria-hidden="true">
                <Box className="py-2 md:py-3">
                  <Box className="border-t border-gray-200" />
                </Box>
              </Box>
              <Box className="text-sm leading-5 font-medium font-poppins">
                <p>Files</p>
                <Box className="flex flex-wrap justify-start items-center gap-3 mt-4 text-brand-500">
                  {comment_file.map(item => (
                    <Box
                      className="flex justify-start items-center gap-3 text-brand-500 cursor-pointer"
                      key={`${item.id} - ${item.name}`}
                      onClick={() => window.open(item.original_url, '_blank')}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M1.33594 11.333V12.1663C1.33594 12.8294 1.59933 13.4653 2.06817 13.9341C2.53701 14.4029 3.1729 14.6663 3.83594 14.6663H12.1693C12.8323 14.6663 13.4682 14.4029 13.937 13.9341C14.4059 13.4653 14.6693 12.8294 14.6693 12.1663V11.333M11.3359 7.99967L8.00261 11.333M8.00261 11.333L4.66927 7.99967M8.00261 11.333V1.33301"
                          stroke="#873CFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>{item.name}</p>
                      <p>
                        {item.file_size &&
                          filesize(item.file_size, {
                            base: 2,
                            standard: 'jedec',
                          }).toString()}
                      </p>
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
