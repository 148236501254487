import { Fragment, ReactElement, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { classNames } from '@/helpers/strings';
import { css, cx } from '@emotion/css';

// Standalone modal form without a button baked in into it.
interface ModalStandaloneProps {
  isOpen: boolean;
  onClose(flag: boolean): void;
  children: ReactElement;
  fullScreen?: boolean;
}

const ModalStandalone = ({
  isOpen,
  children,
  onClose,
  fullScreen = false,
}: ModalStandaloneProps) => {
  const initialRef = useRef(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={initialRef}
        as="div"
        className={classNames(
          'fixed inset-0 z-10',
          fullScreen ? 'overflow-hidden' : 'overflow-y-auto',
        )}
        onClose={onClose}
      >
        <div
          className={cx(
            'text-center',
            fullScreen ? 'h-screen' : 'min-h-screen px-2 sm:px-4',
            css(
              fullScreen
                ? `
              @supports (-webkit-touch-callout: none) {
                height: -webkit-fill-available;
              }
            `
                : `
              @supports (-webkit-touch-callout: none) {
                min-height: -webkit-fill-available;
              }
            `,
            ),
          )}
          ref={initialRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={cx(
              'inline-block h-screen align-middle',
              css(`
                @supports (-webkit-touch-callout: none) {
                  height: -webkit-fill-available;
                }
              `),
            )}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white overscroll-none',
                fullScreen
                  ? 'sm:shadow-xl sm:rounded-2xl'
                  : 'shadow-xl rounded-2xl my-2 sm:my-8',
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default ModalStandalone;
