import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { Disclosure } from '@headlessui/react';
import {
  ChartBarIcon as AnalyticsIcon,
  ChevronDownIcon,
  ClipboardIcon,
  UsersIcon as ContactsIcon,
  KeyIcon,
  ArrowRightStartOnRectangleIcon as LogoutIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  Cog6ToothIcon as SettingsIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import clsx from 'clsx';

import accounts from '@/api/accounts';
import Logo from '@/assets/svg/logos/primary.svg';
import Card from '@/components/Icons/Card';
import Shop from '@/components/Icons/Shop';
import { classNames, getFullName } from '@/helpers/strings';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import SubscriptionStatus from '@/types/SubscriptionStatus';

import Button from './Button';
import DesignWidget from './DesignWidget';
import DashboardIcon from './Icons/Dashboard';

type SidebarSection = 'organisation' | 'insight' | 'accessory';
type SidebarMenuItem = {
  name: string;
  href: string;
  icon: JSX.Element;
  current?: boolean;
  defaultOpen?: boolean;
  onClick?: () => void;
  visibility?: boolean;
  children?: SidebarMenuChildren[];
};

type SidebarMenuChildren = Omit<
  SidebarMenuItem,
  'icon' | 'visibility' | 'defaultOpen' | 'children'
>;

type SidebarItem = {
  id: SidebarSection;
  name: string;
  items: SidebarMenuItem[];
};

type SidebarNavigationProps = {
  pageName: string;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function SidebarNavigation({
  pageName,
  setSidebarOpen,
}: SidebarNavigationProps) {
  const {
    userScope,
    isUserViewForAdmin,
    getCurrentUser,
    logout,
    user,
    userRole,
    adminRole,
    platformSubscriptionStatus,
  } = useAuth();
  const { shopTab, selectShopTab, expandedNavItems, setExpandedNavItems } =
    useAppState();
  const navigate = useNavigate();
  const intercom = useIntercom();

  const isSuperUser = userScope === 'admin';
  const hasSuperUserAccess = isSuperUser && adminRole === null;
  const hasSalesAccess =
    (isSuperUser && adminRole === 'sale') || adminRole === null;
  const hasDesignAccess =
    (isSuperUser && adminRole === 'design') || adminRole === null;

  const hasOrgAdminAccess = userScope === 'user' && userRole === 'org_admin';
  const disableNavigation =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;

  const [shopSsoUrl, setShopSsoUrl] = useState('');
  const shopRef = useRef<HTMLAnchorElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(prev => {
        if (containerRef.current) {
          const scrolled = containerRef.current.scrollTop > 0;
          if (scrolled !== prev) {
            return scrolled;
          }
        }

        return prev;
      });
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (shopSsoUrl) return;
    accounts.getShopifySsoLink().then(res => {
      const ssoRedirect = encodeURIComponent(
        '?redirect_endpoint=/collections/all-products',
      );
      setShopSsoUrl(`${res.data.data.url}${ssoRedirect}`);
    });
  }, [shopSsoUrl]);

  const userNavigation: SidebarItem[] = [
    {
      id: 'organisation',
      name: 'Organisation',
      items: [
        {
          name: 'All Profiles',
          href: '/',
          onClick: () => navigate('/'),
          current: window.location.pathname === '/',
          icon: <UserCircleIcon className="w-5 h-5" strokeWidth={2} />,
        },
        {
          name: 'Groups',
          href: '/groups',
          icon: <UserGroupIcon className="w-5 h-5" strokeWidth={2} />,
          current: window.location.pathname === '/groups',
        },
        {
          name: 'Templates',
          href: '/templates/profile-design',
          icon: <DashboardIcon className="w-5 h-5" />,
          visibility: hasOrgAdminAccess,
          children: [
            {
              name: 'Profile design',
              href: '/templates/profile-design',
              current: window.location.pathname === '/templates/profile-design',
            },
            {
              name: 'Contact exchange',
              href: '/templates/contact-exchange',
              current:
                window.location.pathname === '/templates/contact-exchange',
            },
            {
              name: 'Email signature',
              href: '/templates/email-signature',
              current:
                window.location.pathname === '/templates/email-signature',
            },
            {
              name: 'Digital wallet',
              href: '/templates/digital-wallet',
              current: window.location.pathname === '/templates/digital-wallet',
            },
          ],
        },
      ],
    },
    {
      id: 'insight',
      name: 'Insights',
      items: [
        {
          name: 'Contacts',
          href: '/contacts',
          current: window.location.pathname === '/contacts',
          icon: <ContactsIcon className="w-5 h-5" strokeWidth={2} />,
        },
        {
          name: 'Analytics',
          href: '/analytics2',
          current: pageName === 'Analytics',
          icon: <AnalyticsIcon className="w-5 h-5" strokeWidth={2} />,
          visibility: userRole === 'org_admin',
        },
        {
          name: 'Analytics',
          href: '/analytics',
          current: pageName === 'Analytics',
          icon: <AnalyticsIcon className="w-5 h-5" strokeWidth={2} />,
          visibility: userRole !== 'org_admin',
        },
      ],
    },
    {
      id: 'accessory',
      name: 'Accessories',
      items: [
        {
          name: 'Products',
          href: '/products',
          current: window.location.pathname === '/products',
          icon: <Card className="w-5 h-5" />,
        },
        {
          name: 'Orders',
          href: '/shop',
          current: window.location.pathname === 'shop',
          onClick: () => selectShopTab(0),
          icon: <Shop className="w-5 h-5" />,
          visibility: hasOrgAdminAccess || isSuperUser,
          children: [
            {
              name: 'Design',
              href: '/shop',
              current: window.location.pathname === '/shop' && shopTab === 0,
              onClick: () => selectShopTab(0),
            },
            {
              name: 'Replacements',
              href: '/shop',
              current: window.location.pathname === '/shop' && shopTab === 1,
              onClick: () => selectShopTab(1),
            },
            {
              name: 'Shop',
              href: '/shop',
              onClick: () => shopRef.current?.click(),
            },
          ],
        },
      ],
    },
  ];

  const superUserNavigation: SidebarMenuItem[] = [
    {
      name: 'Products',
      href: '/',
      icon: <Card className="w-6 h-6" />,
      current: window.location.pathname === '/',
      visibility: hasDesignAccess,
    },
    {
      name: 'Profiles',
      href: '/profiles-list',
      icon: <UserCircleIcon className="w-6 h-6" />,
      current: window.location.pathname === '/profiles-list',
    },
    {
      name: 'Activation Keys',
      href: '/activation-keys',
      icon: <KeyIcon className="w-6 h-6" />,
      current: window.location.pathname === '/activation-keys',
      visibility: hasDesignAccess,
    },
    {
      name: 'Organisations',
      href: '/organisations',
      icon: <UserGroupIcon className="w-6 h-6" />,
      current: window.location.pathname === '/organisations',
    },
    {
      name: 'Contacts',
      href: '/contacts',
      icon: <ContactsIcon className="w-6 h-6" />,
      current: window.location.pathname === '/contacts',
    },
    {
      name: 'Analytics',
      href: '/analytics',
      icon: <AnalyticsIcon className="w-6 h-6" />,
      current: window.location.pathname === '/analytics',
    },
    {
      name: 'Superuser Analytics',
      href: '/analytics2',
      onClick: () => navigate('/analytics2'),
      icon: <AnalyticsIcon className="w-6 h-6" />,
      defaultOpen: ['Superuser Analytics', 'Organisation Analytics'].includes(
        pageName,
      ),
      visibility: isUserViewForAdmin || isSuperUser,
    },
    {
      name: 'Orders',
      href: '/orders-v2',
      icon: <ClipboardIcon className="w-6 h-6" />,
      current: window.location.pathname === '/orders-v2',
      visibility: isSuperUser,
    },
    {
      name: 'Pilots',
      href: '/pilots',
      icon: <PaperAirplaneIcon className="w-6 h-6" />,
      current: window.location.pathname === '/pilots',
      visibility: hasSalesAccess,
    },
    {
      name: 'Settings',
      href: '/settings',
      current: window.location.pathname === '/settings',
      icon: <SettingsIcon className="w-6 h-6" />,
      visibility: hasSuperUserAccess,
    },
  ];

  const bottom: SidebarMenuItem[] = [
    {
      name: 'Settings',
      href: '/settings',
      icon: (
        <SettingsIcon
          className="w-5 h-5 group-hover:text-brand-500"
          strokeWidth={2}
        />
      ),
      current: window.location.pathname === '/settings',
      visibility: hasOrgAdminAccess,
    },
  ];

  function renderItem(item: SidebarMenuItem) {
    const linkActiveClasses = item.current
      ? 'bg-brand-100 text-brand-500'
      : 'text-gray-900 group-hover:text-brand-500';
    const linkClasses = disableNavigation
      ? 'text-gray-400 pointer-events-none'
      : linkActiveClasses;

    const chevronClasses = disableNavigation
      ? 'text-gray-400 pointer-events-none'
      : 'text-gray-900 group-hover:text-brand-500';

    if (item.visibility !== undefined && !item.visibility) {
      return null;
    }

    return item.children ? (
      <Disclosure
        as="div"
        defaultOpen={item.defaultOpen || expandedNavItems.includes(item.name)}
      >
        {({ open }) => (
          <div className="space-y-1">
            <div className="group">
              <div
                className={clsx(
                  linkClasses,
                  'flex justify-between rounded-md px-3 py-2 group-hover:bg-brand-100',
                  {
                    'cursor-pointer': !disableNavigation,
                  },
                )}
              >
                <Link
                  to={item.href}
                  className={classNames(
                    linkClasses,
                    'flex items-center leading-6 font-medium space-x-3 text-sm md:text-xs flex-grow',
                  )}
                  onClick={() => {
                    setExpandedNavItems(state =>
                      state.includes(item.name) ? state : [...state, item.name],
                    );

                    return item.onClick?.();
                  }}
                >
                  {item.icon}
                  <span>{item.name}</span>
                </Link>
                <Disclosure.Button
                  className={clsx('flex items-center rounded-md leading-6', {
                    'hover:bg-brand-100': !disableNavigation,
                  })}
                  onClick={() => {
                    setExpandedNavItems(state => {
                      if (!open) {
                        return state.includes(item.name)
                          ? state
                          : [...state, item.name];
                      }

                      return state.filter(i => i !== item.name);
                    });
                  }}
                >
                  <ChevronDownIcon
                    className={classNames(
                      chevronClasses,
                      'w-4 h-4 shrink-0 transition-transform duration-500',
                      open ? 'transform rotate-180' : '',
                    )}
                    strokeWidth={2}
                    aria-hidden="true"
                  />
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel as="ul" className="space-y-1">
              {item.children?.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.href}
                    onClick={item.onClick}
                    className={classNames(
                      item.current
                        ? 'bg-brand-100 text-brand-500'
                        : 'text-gray-900 hover:bg-brand-100',
                      'block rounded-md text-xs p-3',
                    )}
                  >
                    <span className="pl-9">{item.name}</span>
                  </Link>
                </li>
              ))}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    ) : (
      <Link
        to={item.href}
        onClick={item.onClick}
        className={classNames(
          linkClasses,
          'flex items-center px-3 py-2 text-sm md:text-xs leading-6 md:leading-5 font-medium rounded-md transition space-x-3 hover:bg-brand-100',
        )}
      >
        {item.icon}
        <span>{item.name}</span>
      </Link>
    );
  }

  return (
    <div className="relative flex-1 flex flex-col max-w-[235px] h-[100dvh] w-full bg-white">
      <div
        className={`flex flex-shrink-0 items-center h-20 px-3 sticky transition-all border-b ${
          isScrolled ? 'border-gray-300' : 'border-transparent'
        }`}
      >
        <button
          className="inline-flex lg:hidden items-center justify-center h-10 w-10 rounded-full focus:outline-none"
          onClick={() => setSidebarOpen(false)}
          type="button"
        >
          <span className="sr-only">Close sidebar</span>
          <XMarkIcon className="h-6 w-6 text-gray-700" aria-hidden="true" />
        </button>
        <div className="flex-shrink-0 inline-flex items-end">
          <Link to="/">
            <img className="h-8 w-auto" src={Logo} alt="Tapt" />
          </Link>
          {!!import.meta.env.VITE_DISPLAY_ENV &&
            `(${import.meta.env.VITE_DISPLAY_ENV})`}
        </div>
      </div>
      <a ref={shopRef} href={shopSsoUrl} target="_blank" className="hidden" />
      <div className="flex flex-col justify-between flex-grow min-h-0">
        <div
          ref={containerRef}
          className="overflow-y-auto flex-grow space-y-4 hide-scrollbar pb-4"
        >
          {isSuperUser && (
            <nav className="px-2 space-y-1">
              {superUserNavigation.map((item, index) => (
                <Fragment key={index}>{renderItem(item)}</Fragment>
              ))}
            </nav>
          )}
          {!isSuperUser && (
            <nav>
              {userNavigation.map(section => (
                <div key={section.name} className="p-3 space-y-2">
                  <span className="font-medium text-gray-500 text-xs">
                    {section.name}
                  </span>
                  <div className="space-y-1">
                    {section.items.map((item, index) => (
                      <Fragment key={`${item.name}-${index}`}>
                        {renderItem(item)}
                      </Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </nav>
          )}
          {hasOrgAdminAccess && (
            <div className="px-3">
              <DesignWidget />
            </div>
          )}
        </div>
        {/* bottom options */}
        <div className="divide-y divide-gray-300 border-y border-gray-300">
          <div className="p-3">
            {bottom.map(item => {
              if (item.visibility !== undefined && !item.visibility) {
                return null;
              }

              const linkActiveClass = item.current
                ? 'text-brand-500'
                : 'text-gray-500';

              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    linkActiveClass,
                    'flex items-center space-x-2 pl-3 py-2 group',
                  )}
                >
                  <span className={linkActiveClass}>{item.icon}</span>
                  <span className="text-sm md:text-xs group-hover:text-brand-500">
                    {item.name}
                  </span>
                </Link>
              );
            })}
            <button
              type="button"
              className="flex text-gray-500 space-x-2 py-2 pl-3 items-center font-medium group hover:text-brand-500"
              onClick={intercom.isOpen ? intercom.hide : intercom.show}
            >
              <QuestionMarkCircleIcon
                className="w-5 h-5 text-gray-500 group-hover:text-brand-500"
                strokeWidth={2}
              />
              <span className="text-sm md:text-xs group-hover:text-brand-500">
                Help
              </span>
            </button>
          </div>
          {/* logout */}
          {user && (
            // 16.5px to match the height of the info panel footer
            <div className="flex justify-between items-center py-[16.5px] pl-7 pr-5 space-x-2">
              <div className="flex flex-col text-gray-500 w-4/5 text-xs">
                <span className="font-medium truncate">
                  {getFullName({
                    firstName: user.first_name,
                    middleName: null,
                    lastName: user.last_name,
                  })}
                </span>
                <span className="truncate">{user.email}</span>
              </div>
              <button
                type="button"
                className="text-gray-500 hover:text-red-400"
                onClick={logout}
              >
                <LogoutIcon className="w-6 h-6" strokeWidth={2} />
              </button>
            </div>
          )}
          {isUserViewForAdmin && (
            <div className="p-3">
              <Button
                buttonText="Switch to Admin view"
                className="w-full"
                onClick={getCurrentUser}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { SidebarNavigation as default };
