import { classNames } from '@/helpers/strings';
import { IInputSelect, IInputSelectOption } from '@/types/IInput';

export default function InputSelect({
  id,
  label,
  options,
  value,
  message,
  onChange,
  selectClassName = 'block w-full py-2 font-normal text-gray-900 focus:ring-brand-500 focus:border-brand-500 shadow-sm border-gray-300 rounded-md',
  tableInputSelect,
}: IInputSelect): JSX.Element {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={label ? 'mt-1' : ''}>
        <select
          id={id}
          name={id}
          value={value}
          onChange={e => onChange(e.target.value)}
          className={classNames(
            selectClassName,
            tableInputSelect
              ? 'text-gray-700 text-xs font-medium leading-none px-4 !py-2 !pr-6 bg-[url("/images/arrow.png")] [&>option]:w-[100px] overflow-hidden whitespace-nowrap text-ellipsis bg-auto'
              : '',
            label ? 'text-base leading-6' : 'text-sm leading-5',
          )}
        >
          {options.map((option: IInputSelectOption) => (
            <option
              key={option.value}
              value={option.value}
              hidden={option.hidden}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {message && (
        <p className="mt-2 text-sm leading-5 text-gray-500">{message}</p>
      )}
    </div>
  );
}
