const COLORS = {
  organisation: {
    loadingProfile: {
      backgroundColor: '#FFFFFF',
      buttonBackgroundColor: '#873CFF',
      buttonTextColor: '#FFFFFF',
      textColor: '#0A0A0A',
    },
    defaultProfile: {
      backgroundColor: '#FFFFFF',
      buttonBackgroundColor: '#873CFF',
      buttonTextColor: '#FFFFFF',
      textColor: '#0A0A0A',
    },
  },
  profile: {
    footer: {
      copyrightMessage: `© ${new Date().getFullYear()} Tapt by Hatch`,
      colorTextLight: '#FFFFFF',
      colorTextDark: '#000000',
    },
  },
};

export const gray = {
  '50': '#F9FAFB',
  '100': '#F3F4F6',
  '200': '#E5E7EB',
  '300': '#D1D5DB',
  '400': '#9CA3AF',
  '500': '#6B7280',
  '600': '#4B5563',
  '700': '#374151',
  '800': '#1F2937',
  '900': '#111827',
};

export const brand = {
  '50': '#f9f5ff',
  '100': '#f3ecff',
  '200': '#e1ceff',
  '300': '#cfb1ff',
  '400': '#ab77ff',
  '500': '#873cff',
  '600': '#7a36e6',
  '700': '#652dbf',
  '800': '#512499',
  '900': '#421d7d',
};

export { COLORS as default, COLORS };
