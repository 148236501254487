import { useState } from 'react';
import { useQuery } from 'react-query';

import {
  DocumentMagnifyingGlassIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';

import clsx from 'clsx';

import ordersAPI from '@/api/ordersV2';
import Button, { BUTTON_KIND } from '@/components/Button';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import { getVariantFullName } from '@/helpers/products';
import { adjustToLocalTime } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import IOrder, { OrderStage } from '@/types/IOrderV2';

const sortOptions = [
  {
    sort: 'inserted_at',
    order: 'desc',
    label: 'Date created (Newest first)',
  },
  {
    sort: 'inserted_at',
    order: 'asc',
    label: 'Date created (Oldest first)',
  },
  {
    sort: 'quantity',
    order: 'asc',
    label: 'No of Profile (Lowest first)',
  },
  {
    sort: 'quantity',
    order: 'desc',
    label: 'No of Profile (Highest first)',
  },
  {
    sort: 'type',
    order: 'shop',
    label: 'Order Type (Shop)',
  },
  {
    sort: 'type',
    order: 'draft',
    label: 'Order Type (Draft)',
  },
];

export default function OrdersTab() {
  const { orgID } = useAuth();

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('updated_at');
  const [order, setOrder] = useState('desc');

  const { data: orders } = useQuery(
    ['listOrdersUserOrganisation', orgID, page, pageSize, sort, order, search],
    () =>
      listOrders({
        page,
        pageSize,
        order,
        sort,
        search,
      }),
    {
      enabled: orgID !== undefined,
    },
  );

  async function listOrders({
    page,
    pageSize,
    search,
    order,
    sort,
  }: {
    page: number;
    pageSize: number;
    sort: string;
    order: string;
    search: string;
  }) {
    const res = await ordersAPI.listOrdersUserOrganisation(orgID || -1, {
      page,
      pageSize,
      order,
      sort,
      search,
    });

    return res.data;
  }

  return (
    <div className="space-y-8 pt-8">
      <div className="flex justify-end space-x-4">
        <Search
          id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          fetchQuery={newSearch =>
            listOrders({
              page,
              pageSize,
              search: newSearch,
              sort,
              order,
            })
          }
        />
        <Sort
          id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
          options={sortOptions}
          sort={sort}
          setSort={setSort}
          order={order}
          setOrder={setOrder}
          fetchQuery={(newSort, newOrder) =>
            listOrders({
              sort: newSort,
              order: newOrder,
              search,
              page,
              pageSize,
            })
          }
        />
      </div>
      {orders ? (
        orders.data.length > 0 ? (
          <>
            <div className="hidden xl:block relative border border-solid rounded-lg overflow-hidden">
              <table
                className={clsx('w-full max-w-full table-fixed', {
                  'opacity-40': isPaginationLoading,
                })}
              >
                <thead className="bg-gray-100 border-b border-gray-200">
                  <tr className="uppercase text-gray-900 text-sm">
                    <th scope="col" className="font-medium py-3 px-4 text-left">
                      Order &amp; date
                    </th>
                    <th scope="col" className="font-medium p-3 text-left">
                      Product
                    </th>
                    <th scope="col" className="font-medium p-3">
                      Status
                    </th>
                    <th scope="col" className="font-medium py-3 px-4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {orders.data.map((order, index) => (
                    <OrdersListItem key={index} order={order} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="xl:hidden space-y-4">
              <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md font-medium pl-4 mx:px-6 py-4 uppercase">
                Order no., date placed, product, status, action or view design
              </div>
              {orders.data.map((order, index) => (
                <OrderListItemMobile key={index} order={order} />
              ))}
            </div>
          </>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.order.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.order.empty.description}
            </p>
          </div>
        )
      ) : (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
        </div>
      )}
      <Pagination
        id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
        className="bg-gray-50"
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(newPage, newPageSize) =>
          listOrders({
            page: newPage,
            pageSize: newPageSize,
            order,
            sort,
            search,
          })
        }
        setIsLoading={setIsPaginationLoading}
      />
    </div>
  );
}

function DesignLabel({ orderStage }: { orderStage: OrderStage }) {
  const OrderDesignMap: Record<OrderStage, { label: string; color: string }> = {
    design_needed: { label: 'Design needed', color: 'brand' },
    new: { label: 'Design in progress', color: 'amber' },
    design_edits: { label: 'Design in progress', color: 'amber' },
    on_hold: { label: 'On hold', color: 'gray' },
    awaiting_client: { label: 'Approval needed', color: 'red' },
    in_print: { label: 'Printing', color: 'blue' },
    ready_for_print: { label: 'Printing', color: 'blue' },
    fulfilled: { label: 'Design complete', color: 'emerald' },
  } as const;

  return (
    <div
      className={`rounded-full h-5 px-2 max-w-max bg-${OrderDesignMap[orderStage].color}-100`}
    >
      <p
        className={`text-xs text-center font-medium h-full flex items-center justify-center text-${OrderDesignMap[orderStage].color}-800`}
      >
        {OrderDesignMap[orderStage].label}
      </p>
    </div>
  );
}

export function OrdersListItem({ order }: { order: IOrder }) {
  const {
    uuid,
    order_stage: orderStage,
    order_status_url: orderStatusUrl,
    inserted_at: insertedAt,
    order_number: orderNumber,
    order_quantity: orderQuantity,
    card_type: cardType,
  } = order;

  const [date] = adjustToLocalTime(insertedAt).split(' ');

  const designNow = `${import.meta.env.VITE_CUSTOMISER_BASE_URL}/order/${uuid}`;
  const reviewProof = `/preview/${uuid}`;
  const finalDesign = `/preview/${uuid}`;

  const FinalDesign = () => (
    <Button
      buttonText="Final design"
      href={finalDesign}
      kind={BUTTON_KIND.WHITE}
      icon={<EyeIcon className="w-5 h-5" />}
    />
  );

  const OrderStageActionMap: Record<OrderStage, JSX.Element> = {
    // design needed
    design_needed: (
      <Button
        buttonText="Design now"
        href={designNow}
        icon={<PencilIcon className="w-5 h-5" />}
      />
    ),
    // design in progress
    new: <></>,
    design_edits: <></>,
    on_hold: <></>,
    // approval needed
    awaiting_client: (
      <Button
        buttonText="Review proof"
        href={reviewProof}
        kind={BUTTON_KIND.LIGHT_RED}
        icon={<DocumentMagnifyingGlassIcon className="w-5 h-5" />}
      />
    ),
    // design complete
    in_print: <FinalDesign />,
    ready_for_print: <FinalDesign />,
    fulfilled: <FinalDesign />,
  } as const;

  return (
    <tr className="hover:bg-gray-50">
      <td className="whitespace-nowrap text-sm py-3 px-4">
        <span className="flex flex-col">
          <a
            className="text-brand-500 font-medium flex space-x-1 w-min"
            href={orderStatusUrl}
            target="_blank"
          >
            <span>#{orderNumber}</span>
            <ExternalLinkIcon className="w-4 h-4" strokeWidth={2} />
          </a>
          <span className="text-gray-500">{date}</span>
        </span>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 p-3">
        <span className="flex flex-col">
          <span className="font-medium">{getVariantFullName(cardType)}</span>
          <span className="text-gray-500">
            {orderQuantity} pc{orderQuantity > 1 && 's'}
          </span>
        </span>
      </td>
      <td className="whitespace-nowrap text-sm font-medium text-brand-500 p-3">
        <span className="flex justify-center">
          <DesignLabel orderStage={orderStage} />
        </span>
      </td>
      <td className="whitespace-nowrap text-sm font-medium text-brand-500 py-3 px-4">
        <span className="flex justify-center">
          {OrderStageActionMap[orderStage]}
        </span>
      </td>
    </tr>
  );
}

export function OrderListItemMobile({ order }: { order: IOrder }) {
  const {
    uuid,
    order_stage: orderStage,
    order_status_url: orderStatusUrl,
    inserted_at: insertedAt,
    order_number: orderNumber,
    order_quantity: orderQuantity,
    card_type: cardType,
  } = order;

  const [date] = adjustToLocalTime(insertedAt).split(' ');

  const orderInfo = [
    {
      title: 'Order & date',
      value: (
        <span className="flex items-center space-x-2">
          <a
            className="text-brand-500 flex space-x-1"
            href={orderStatusUrl}
            target="_blank"
          >
            <span>#{orderNumber}</span>
            <ExternalLinkIcon className="w-4 h-4" strokeWidth={2} />
          </a>
          <span>{date}</span>
        </span>
      ),
    },
    {
      title: 'Product',
      value: getVariantFullName(cardType),
    },
    {
      title: 'Quantity',
      value: (
        <span>
          {orderQuantity} pc{orderQuantity > 1 && 's'}
        </span>
      ),
    },
    {
      title: 'Status',
      value: <DesignLabel orderStage={orderStage} />,
    },
  ];

  const designNow = `${import.meta.env.VITE_CUSTOMISER_BASE_URL}/order/${uuid}`;
  const reviewProof = `./preview/${uuid}`;
  const finalDesign = `./preview/${uuid}`;

  const FinalDesign = () => (
    <a
      href={finalDesign}
      className="flex justify-center space-x-3 p-4"
      target="_blank"
    >
      <EyeIcon className="w-5 h-5 text-gray-500" />
      <span className="text-gray-700">Final design</span>
    </a>
  );

  const OrderStageActionMap: Record<OrderStage, JSX.Element> = {
    // design needed
    design_needed: (
      <a
        href={designNow}
        className="flex justify-center space-x-3 p-4"
        target="_blank"
      >
        <PencilIcon className="w-5 h-5 text-gray-500" />
        <span className="text-gray-700">Design now</span>
      </a>
    ),
    // design in progress
    new: <></>,
    design_edits: <></>,
    on_hold: <></>,
    // approval needed
    awaiting_client: (
      <a
        href={reviewProof}
        className="flex justify-center space-x-3 p-4"
        target="_blank"
      >
        <DocumentMagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
        <span className="text-gray-700">Review proof</span>
      </a>
    ),
    // design complete
    in_print: <FinalDesign />,
    ready_for_print: <FinalDesign />,
    fulfilled: <FinalDesign />,
  } as const;

  return (
    <div className="border border-gray-300 bg-white rounded-md flex flex-col divide-y divide-gray-300">
      <div className="p-6 space-y-4">
        {orderInfo.map((item, index) => (
          <div key={index} className="flex justify-between">
            <span className="text-gray-900 text-sm font-medium">
              {item.title}
            </span>
            <span className="text-sm text-gray-500">{item.value}</span>
          </div>
        ))}
      </div>
      {OrderStageActionMap[orderStage]}
    </div>
  );
}
