import { VariantValue } from './IProduct';

export const OrderTagValues = [
  'setup',
  'ready',
  'awaiting',
  'on_hold',
  'design',
] as const;

export type OrderTag = (typeof OrderTagValues)[number];

export type OrderPausedBy = 'tapt_design' | 'client';

export type OrderOrigin = 'draft' | 'shop' | 'web_app' | 'officeworks';

export type OrderDesignFile = {
  blur_hash: string | null;
  blur_url: string;
  file: {
    file_name: string;
    updated_at: string;
  };
  id: number;
  large_url: string;
  medium_url: string;
  name: string;
  original_url: string;
  small_url: string;
  thumb_url: string;
  upload_url: string;
};

export type OrderDetails = {
  card_type: VariantValue;
  customer_email: string;
  design_file: OrderDesignFile | null;
  id: number;
  inserted_at: string;
  order_number: string;
  order_quantity: number;
  order_stage: OrderStage;
  order_status_url: string;
  order_tag: OrderTag;
  order_url: string;
  organisation_id: number;
  uuid: string;
};

export type OrderCardDetails = { cards: Record<string, string>[] };

export type OrderStage =
  | 'new'
  | 'awaiting_client'
  | 'design_edits'
  | 'ready_for_print'
  | 'in_print'
  | 'fulfilled'
  | 'on_hold'
  | 'design_needed';

export const OrderStageName: Record<OrderStage, string> = {
  new: 'New',
  awaiting_client: 'With client',
  design_edits: 'Edit',
  ready_for_print: 'Ready for print',
  fulfilled: 'Fulfilled',
  in_print: 'In Print',
  on_hold: 'On Hold',
  design_needed: 'Design needed',
} as const;

export default interface IOrder {
  id: number;
  uuid: string;
  order_url: string;
  order_status_url: string;
  order_number: string;
  customer_name: string;
  customer_email: string;
  order_quantity: number;
  order_origin: OrderOrigin;
  order_stage: OrderStage;
  notifications_sent: string;
  organisation_id: number;
  fulfillment_status: string;
  inserted_at: string;
  updated_at?: string;
  is_priority?: boolean;
  alternate_email?: string;
  alternate_email_flag: boolean;
  tag: OrderTag;
  paused_by?: OrderPausedBy;
  design_file: OrderDesignFile | null;
  shipping_country: string | null;
  has_generated_cards: boolean;
  cards_details: OrderCardDetails | null;
  design_details: Record<string, string> | null;
  card_type: VariantValue;
  front_logo_url: string | null;
  back_logo_url: string | null;
}

export enum Role {
  DESIGN = 'design',
  SALE = 'sale',
}

export type IOrderUser = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: Role;
};
