import React, { Fragment } from 'react';
import { useQuery } from 'react-query';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { classNames } from '@/helpers/strings';
import IActivationKey from '@/types/IActivationKey';
import IAnalytics, { IAnalyticsAdmin } from '@/types/IAnalytics';
import { ICardAdmin, ICardUser } from '@/types/ICard';
import IContacts from '@/types/IContacts';
import { IListPaging } from '@/types/IList';
import IOrder from '@/types/IOrderV2';
import IOrganisation, {
  IOrganisationGroup,
  IPilot,
} from '@/types/IOrganisation';
import { IProfileBase } from '@/types/IProfile';

export interface ISortOption {
  sort: string;
  order: string;
  label: string;
}

interface ISort {
  id: string;
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  order: string;
  setOrder: React.Dispatch<React.SetStateAction<string>>;
  fetchQuery: (
    sort: string,
    order: string,
  ) => Promise<{
    data: (
      | IActivationKey
      | IAnalytics
      | IAnalyticsAdmin
      | ICardAdmin
      | ICardUser
      | IContacts
      | IOrganisationGroup
      | IOrganisation
      | IProfileBase
      | IOrder
      | IPilot
    )[];
    paging: IListPaging;
  }>;
  fetchQueryEnabled?: boolean;
  options: ISortOption[];
}

export default function Sort({
  id,
  sort = 'date',
  setSort,
  order = 'asc',
  setOrder,
  fetchQuery,
  fetchQueryEnabled = true,
  options,
}: ISort) {
  useQuery(id, () => fetchQuery(sort, order), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: fetchQueryEnabled,
  });

  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      <div>
        <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
          Sort by
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map(item => (
              <Menu.Item key={item.label}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => {
                      setSort(item.sort);
                      setOrder(item.order);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full text-left block px-4 py-2 text-sm',
                    )}
                  >
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
