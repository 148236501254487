import { useState } from 'react';

import { ReactComponent as BlankProfileAvatar } from '@/assets/svg/default_avatar.svg';
import Copy from '@/components/Icons/Copy';
import InputCheckbox from '@/components/InputCheckbox';
import { copyToClipboard, hashTruncate } from '@/helpers/strings';
import { IProfileBase } from '@/types/IProfile';

type ProfileListGroupItemProps = {
  profile: IProfileBase;
  selected: boolean;
  checkItem: () => void;
};

export function ProfileListGroupItem({
  profile,
  selected,
  checkItem,
}: ProfileListGroupItemProps) {
  const [isCopied, setIsCopied] = useState(false);

  const {
    profile_hash: profileHash,
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    group_name: groupName,
    group_subheading: groupSubheading,
    photo,
  } = profile;

  function copyHash() {
    copyToClipboard(profileHash, () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  }

  return (
    <tr className="relative hover:bg-gray-50 cursor-pointer">
      <td className="pl-6">
        <InputCheckbox
          id="add-profiles-modal"
          value={selected}
          onChange={checkItem}
        />
        <a onClick={checkItem} className="after:absolute after:inset-0" />
      </td>
      <td className="text-sm text-gray-900 py-3 px-6">
        <div className="flex flex-row items-center gap-2">
          <span>
            {photo?.thumb_url ? (
              <img
                className="w-10 h-10 rounded-full"
                src={photo.thumb_url}
                alt="Profile photo"
              />
            ) : (
              <BlankProfileAvatar className="w-10 h-10 rounded-full" />
            )}
          </span>
          <span className="w-5/6">
            <span className="font-medium text-gray-900 block truncate">
              {firstName} {lastName}
            </span>
            <button
              className="text-brand-500 flex flex-row items-center"
              onClick={copyHash}
            >
              <span className="text-gray-500">ID:&nbsp;</span>
              {isCopied ? 'Copied!' : hashTruncate(profileHash)}
              <Copy className="w-4 h-4 ml-1" />
            </button>
          </span>
        </div>
      </td>
      <td className="text-sm text-gray-900 py-3 px-6">{jobTitle}</td>
      <td className="text-sm text-gray-900 py-3 px-6">
        <span className="flex flex-col font-medium">
          {groupName && <span className="block truncate">{groupName}</span>}
          {groupSubheading && (
            <span className="text-gray-500">{groupSubheading}</span>
          )}
        </span>
      </td>
    </tr>
  );
}

export function ProfileListGroupItemMobile({
  profile,
  selected,
  checkItem,
}: ProfileListGroupItemProps) {
  const {
    profile_hash: profileHash,
    first_name: firstName,
    last_name: lastName,
    email,
    job_title: jobTitle,
    group_name: groupName,
    group_subheading: groupSubheading,
    photo,
  } = profile;

  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="relative border border-gray-300 bg-white rounded-md xl:hidden my-4 cursor-pointer">
      <a className="after:absolute after:inset-0" onClick={checkItem} />
      <div className="flex flex-row items-center justify-between px-6 py-4">
        <span className="self-start">
          <InputCheckbox
            id={`Checkbox-${profile.status}-${profile.id}`}
            label=""
            value={selected}
            onChange={checkItem}
          />
        </span>
        <div className="flex flex-col w-full px-2">
          <div className="flex flex-row space-x-3">
            <div className="flex-shrink-0">
              {photo?.thumb_url ? (
                <img
                  className="h-10 w-10 rounded-full"
                  src={photo.thumb_url}
                  alt="Profile photo"
                />
              ) : (
                <BlankProfileAvatar className="w-10 h-10 rounded-full" />
              )}
            </div>
            <div className="flex flex-col flex-grow min-w-0">
              <span className="truncate font-medium pr-4">
                {firstName} {lastName}
              </span>
              <span className="truncate flex flex-row items-center text-gray-500 z-10">
                ID:&nbsp;
                <button
                  type="button"
                  className="appearance-none text-brand-600 text-sm font-medium items-center flex flex-row"
                  onClick={() =>
                    copyToClipboard(profileHash, () => {
                      setIsCopied(true);
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 1000);
                    })
                  }
                >
                  {isCopied ? 'Copied!' : hashTruncate(profileHash)}
                  <Copy className="w-4 h-4 ml-1" />
                </button>
              </span>
            </div>
          </div>
          <span className="truncate font-medium pt-2">{jobTitle}</span>
          {email && (
            <span className="text-gray-500 pr-4 truncate">{email}</span>
          )}
          {groupName && (
            <span className="truncate font-medium pt-2">{groupName}</span>
          )}
          {groupSubheading && (
            <span className="text-gray-500 truncate">{groupSubheading}</span>
          )}
        </div>
      </div>
    </div>
  );
}
