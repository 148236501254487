import { useLocation, useParams } from 'react-router-dom';

const DEFAULT_PROFILE_LINK = 'https://profile.tapt.io';

function ViewProfilePage() {
  const { search } = useLocation();
  const { profile_hash_id: profileHashID } = useParams<{
    profile_hash_id: string;
  }>();
  const queryParams = new URLSearchParams(search);

  window.location.replace(
    `${
      import.meta.env.VITE_PROFILE_LINK || DEFAULT_PROFILE_LINK
    }/profile/${profileHashID}?ref=${queryParams.get('ref') || ''}`,
  );

  return null;
}

export { ViewProfilePage as default };
