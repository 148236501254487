import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import organisationsAPI from '@/api/organisations';
import Layout from '@/components/Layout';
import OrganisationSettings from '@/containers/OrganisationSettings';
import { IOrganisationUser } from '@/types/IOrganisation';

export default function ViewOrganisationPage() {
  const { organisation_id: orgID } = useParams<{ organisation_id: string }>();
  const [organisationName, setOrganisationName] = useState<string>('');
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);

  const showOrganisation = useCallback(() => {
    if (orgID === undefined) {
      return;
    }

    organisationsAPI
      .showOrganisation(parseInt(orgID))
      .then(res => {
        setOrganisationName(res?.data?.data?.name);
      })
      .finally(() => {});
  }, [orgID, setOrganisationName]);

  const getOrganisationUsers = useCallback(() => {
    if (orgID === undefined) {
      return;
    }

    organisationsAPI
      .listOrganisationRoles({ orgID: parseInt(orgID) })
      .then(res => setOrgUsers(res.data?.data))
      .finally(() => {});
  }, [orgID, setOrgUsers]);

  useEffect(() => {
    showOrganisation();
    getOrganisationUsers();
  }, [showOrganisation, getOrganisationUsers]);

  return (
    <Layout pageName="Organisation Settings">
      <OrganisationSettings
        orgID={parseInt(orgID || '-1')}
        organisationName={organisationName}
        setOrganisationName={setOrganisationName}
        orgUsers={orgUsers}
        setOrgUsers={setOrgUsers}
        disabled
      />
    </Layout>
  );
}
