import { useState } from 'react';

import { clsx } from 'clsx';
import { useCopyToClipboard } from 'usehooks-ts';

import Copy from '@/components/Icons/Copy';
import { brand } from '@/constants/colors';
import { ICardAdmin } from '@/types/ICard';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: brand['100'],
  },
  arrow: {
    color: brand['100'],
  },
});

const CardUrlCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const classes = useStyles();
  const [, copy] = useCopyToClipboard();
  const [urlCopied, setUrlCopied] = useState(false);

  const cardUrl = card.card_page_url;
  async function copyUrlToClipboard() {
    await copy(cardUrl);

    setUrlCopied(true);
    setTimeout(() => {
      setUrlCopied(false);
    }, 3000);
  }

  return (
    <td className={'p-4 pb-3 hidden md:table-cell'}>
      <Tooltip
        title={
          <span className="text-brand-500 text-sm font-medium font-poppins px-1">
            Copied!
          </span>
        }
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={urlCopied}
        placement="left"
        classes={classes}
      >
        <div className="hover:cursor-pointer" onClick={copyUrlToClipboard}>
          <Copy
            className={clsx(
              'w-6 h-6',
              urlCopied ? 'text-brand-500' : 'text-gray-400',
            )}
          />
        </div>
      </Tooltip>
    </td>
  );
};

export default CardUrlCell;
