import { useNavigate } from 'react-router-dom';

import { ICardAdmin } from '@/types/ICard';

const ProfileCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const navigate = useNavigate();
  const profile = card.profile;
  const fullName =
    profile && [profile.first_name, profile.last_name].filter(v => v).join(' ');

  return (
    <td className="p-4 pb-3 hidden md:table-cell">
      {profile && (
        <>
          {fullName && (
            <p className="font-medium truncate text-sm text-gray-900">
              {fullName}
            </p>
          )}
          <button
            type="button"
            onClick={() =>
              navigate(`/edit-profile/${profile.id}`, {
                state: {
                  fromName: 'Cards',
                  fromURL: '/',
                },
              })
            }
            className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
          >
            <span className="text-sm md:hidden">Profile ID:&nbsp;</span>
            {`#${profile.id}`}
          </button>
        </>
      )}
    </td>
  );
};

export default ProfileCell;
