import { RadioGroup } from '@headlessui/react';

import { classNames } from '@/helpers/strings';

interface IRadioGroup {
  settings: {
    name: string;
    description: string;
    value: number;
  }[];
  value: number;
  setter: React.Dispatch<React.SetStateAction<number>>;
  error?: boolean;
  disabled?: boolean;
}

export default function Radio({
  settings,
  value,
  setter,
  error,
  disabled,
}: IRadioGroup) {
  return (
    <RadioGroup value={value} onChange={setter} disabled={disabled}>
      <RadioGroup.Label className="sr-only">Radio group</RadioGroup.Label>
      <div
        className={classNames(
          error ? 'border-red-500' : 'border-gray-200',
          'bg-white rounded-md -space-y-px border',
        )}
      >
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting.value}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === settings.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'bg-brand-100 border-brand-200' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-brand-500 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-brand-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className="text-gray-900 block text-sm font-medium"
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className="text-gray-500 block text-sm"
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
