import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import profilesAPI from '@/api/profiles';
import EmailSignaturePreview from '@/components/Templates/EmailSignaturePreview';
import { IEmailSignature } from '@/types/IOrganisation';
import { IProfileSignature } from '@/types/IProfile';

export default function EmailSignaturePreviewPage() {
  const { profile_hash_id } = useParams<{ profile_hash_id: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<{
    signature: IEmailSignature;
    profile: IProfileSignature;
  } | null>(null);
  const signatureRef = useRef<HTMLDivElement>(null);
  const [copyStatus, setCopyStatus] = useState<'idle' | 'copied'>('idle');

  useEffect(() => {
    const fetchSignature = async () => {
      try {
        if (!profile_hash_id) {
          setError('No profile hash provided');
          return;
        }
        const response = await profilesAPI.getEmailSignature(profile_hash_id);
        setData(response.data.data);
      } catch (err) {
        setError('Failed to load email signature');
      } finally {
        setLoading(false);
      }
    };

    fetchSignature();
  }, [profile_hash_id]);

  const handleCopyToClipboard = async () => {
    if (!signatureRef.current) return;

    try {
      // Try modern Clipboard API first
      if (navigator.clipboard && window.ClipboardItem) {
        const htmlContent = signatureRef.current.innerHTML;
        const plainText = signatureRef.current.textContent || '';

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
            'text/plain': new Blob([plainText], { type: 'text/plain' }),
          }),
        ]);
      } else {
        // Fallback to Selection API
        const range = document.createRange();
        range.selectNodeContents(signatureRef.current);

        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);

        document.execCommand('copy');
        selection?.removeAllRanges();
      }

      // Update UI state
      setCopyStatus('copied');
      setTimeout(() => setCopyStatus('idle'), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="w-full max-w-[600px] bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-center p-8">Loading...</div>
        </div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="w-full max-w-[600px] bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-center p-8 text-red-600">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-[600px] bg-white rounded-lg shadow-sm p-6">
        <h1 className="text-xl font-semibold text-gray-900 mb-6">
          Email Signature Preview
        </h1>
        <div ref={signatureRef}>
          <EmailSignaturePreview
            signature={data.signature}
            profile={data.profile}
          />
        </div>
        <div className="mt-6 flex justify-center">
          <button
            onClick={handleCopyToClipboard}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {copyStatus === 'copied' ? 'Copied!' : 'Copy Signature'}
          </button>
        </div>
      </div>
    </div>
  );
}
