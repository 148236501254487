import { useState } from 'react';
import { Link } from 'react-router-dom';

import { PencilIcon } from '@heroicons/react/24/outline';

import Copy from '@/components/Icons/Copy';
import { copyToClipboard, hashTruncate } from '@/helpers/strings';
import { IOrganisationGroup } from '@/types/IOrganisation';

import InputCheckbox from './InputCheckbox';

interface ProfileGroupCardProps {
  groupHash: string;
  noOfEditors: number;
  noOfProfiles: number;
  groupName: string;
}

type ProfileGroupCardInfo = {
  title: string;
  value: string;
  copyable: boolean;
};

export function ProfileGroupCard({
  groupHash,
  noOfEditors,
  noOfProfiles,
  groupName,
}: ProfileGroupCardProps) {
  const [isCopied, setIsCopied] = useState(false);

  const groupInfo: ProfileGroupCardInfo[] = [
    { title: 'Group ID', value: hashTruncate(groupHash), copyable: true },
    { title: 'Group Name', value: groupName, copyable: false },
    { title: 'Managers', value: noOfEditors.toString(), copyable: false },
    { title: 'Profiles', value: noOfProfiles.toString(), copyable: false },
  ];

  function copyValue(value: string) {
    copyToClipboard(value, () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  }

  return (
    <div
      key={groupHash}
      className="flex flex-col justify-between shadow-sm bg-white border border-gray-300 divide-y divide-gray-300 sm:rounded-xl min-h-8 overflow-hidden rounded-md"
    >
      <div className="pb-3">
        {groupInfo.map((item, index) => (
          <div key={index} className="flex flex-row justify-between px-6 pt-3">
            <span className="text-gray-900 font-medium">{item.title}</span>
            {item.copyable ? (
              <button
                type="button"
                className="flex flex-row gap-1 items-center text-brand-500"
                onClick={() => copyValue(item.value)}
              >
                <span className="text-gray-500">ID:&nbsp;</span>
                <span className="text-right">
                  {isCopied ? 'Copied!' : item.value}
                </span>
                <Copy className="w-4 h-4" />
              </button>
            ) : (
              <span className="text-gray-500">{item.value}</span>
            )}
          </div>
        ))}
      </div>
      <Link
        to={`/edit-group/${groupHash}`}
        className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700"
      >
        <PencilIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
        <span className="ml-2 text-sm">Edit Group</span>
      </Link>
    </div>
  );
}

export function ProfileGroupItemRow({ group }: { group: IOrganisationGroup }) {
  const [isCopied, setIsCopied] = useState(false);

  function copyHash() {
    copyToClipboard(group.group_hash, () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  }

  return (
    <tr className="hover:bg-gray-50">
      <td className="whitespace-nowrap text-sm font-medium text-brand-500 py-3 px-6">
        <button
          type="button"
          className="flex items-center gap-1"
          onClick={copyHash}
          disabled={isCopied}
        >
          <span className="text-gray-500">ID:&nbsp;</span>
          <span className="w-12 text-left">
            {isCopied ? 'Copied!' : hashTruncate(group.group_hash)}
          </span>
          <Copy className="w-4 h-4 ml-1" />
        </button>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 w-1/2 py-3 px-6">
        {group.name}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 py-3 px-6 text-center">
        {group.editors.length}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center py-3 px-6">
        {group.profiles_count}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 py-3 px-6">
        <span className="flex items-center justify-center">
          <Link to={`/edit-group/${group.group_hash}`}>
            <PencilIcon className="w-5 h-5 text-gray-900" strokeWidth={1.6} />
          </Link>
        </span>
      </td>
    </tr>
  );
}

type CheckableProfileItemRowProps = {
  group: IOrganisationGroup;
  checked: boolean;
  onCheckChange: () => void;
};

export function CheckableProfileItemRow({
  group,
  checked,
  onCheckChange,
}: CheckableProfileItemRowProps) {
  const [isCopied, setIsCopied] = useState(false);

  function copyHash() {
    copyToClipboard(group.group_hash, () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  }

  return (
    <tr className="relative hover:bg-gray-50 cursor-pointer">
      <td className="py-3 px-6">
        <InputCheckbox
          id={`check-${group.id}`}
          value={checked}
          onChange={onCheckChange}
        />
        <a onClick={onCheckChange} className="after:absolute after:inset-0" />
      </td>
      <td className="whitespace-nowrap text-sm font-medium text-brand-500 py-3 px-6">
        <button
          type="button"
          className="flex flex-row items-center gap-1"
          onClick={copyHash}
        >
          <span className="text-gray-500">ID:&nbsp;</span>
          <span className="w-12 text-left">
            {isCopied ? 'Copied!' : hashTruncate(group.group_hash)}
          </span>
          <Copy className="w-4 h-4 ml-1" />
        </button>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 w-1/2 py-3 px-6">
        {group.name}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 py-3 px-6 text-center">
        {group.editors.length}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center py-3 px-6">
        {group.profiles_count}
      </td>
    </tr>
  );
}

type CheckableProfileGroupProps = ProfileGroupCardProps & {
  checked: boolean;
  onCheckChange: () => void;
};

export function CheckableProfileGroupCard({
  groupHash,
  groupName,
  noOfEditors,
  noOfProfiles,
  checked,
  onCheckChange,
}: CheckableProfileGroupProps) {
  const [isCopied, setIsCopied] = useState(false);

  const groupInfo: ProfileGroupCardInfo[] = [
    { title: 'Group ID', value: hashTruncate(groupHash), copyable: true },
    { title: 'Group Name', value: groupName, copyable: false },
    { title: 'Managers', value: noOfEditors.toString(), copyable: false },
    { title: 'Profiles', value: noOfProfiles.toString(), copyable: false },
  ];

  function copyValue(value: string) {
    copyToClipboard(value, () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  }

  return (
    <div
      key={groupHash}
      className="relative flex flex-row shadow-md bg-white border border-gray-300 divide-y divide-gray-300 sm:rounded-xl min-h-8 overflow-hidden rounded-md"
    >
      <div className="pl-6 pr-3 py-3">
        <InputCheckbox
          id={`check-${groupHash}`}
          value={checked}
          onChange={onCheckChange}
        />
      </div>
      <a onClick={onCheckChange} className="after:absolute after:inset-0" />
      <div className="pb-3 flex-1">
        {groupInfo.map((item, index) => (
          <div key={index} className="flex flex-row justify-between pr-6 pt-3">
            <span className="text-gray-900 font-medium">{item.title}</span>
            {item.copyable ? (
              <button
                type="button"
                className="flex flex-row gap-1 items-center text-brand-500"
                onClick={() => copyValue(item.value)}
              >
                <span className="text-gray-500">ID:&nbsp;</span>
                <span className="text-right">
                  {isCopied ? 'Copied!' : item.value}
                </span>
                <Copy className="w-4 h-4" />
              </button>
            ) : (
              <span className="text-gray-500">{item.value}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
