import { useState } from 'react';

import { Menu } from '@headlessui/react';

import { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modals/Modal';
import {
  BaseTab,
  CARD_STATUS,
  IBaseTabState,
  IItem,
} from '@/components/ProductsAdminPage/BaseTab';
import ActivationKeyCell from '@/components/ProductsAdminPage/Cell/ActivationKeyCell';
import CardUrlCell from '@/components/ProductsAdminPage/Cell/CardUrlCell';
import CreatedCell from '@/components/ProductsAdminPage/Cell/CreatedCell';
import TypeCell from '@/components/ProductsAdminPage/Cell/TypeCell';
import ActionsMenu from '@/components/ProductsAdminPage/Menu/ActionsMenu';
import ConnectProfileModal from '@/components/ProductsAdminPage/Modal/ConnectProfileModal';

const status = CARD_STATUS.UNPROGRAMMED;

const Header: React.FC = () => (
  <>
    <th className="font-medium p-4 pb-3">TYPE &amp; ID</th>
    <th className="font-medium p-4 pb-3">CREATED</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell">
      ACTIVATION KEY
    </th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell w-16">URL</th>
    <th className="font-medium p-4 pb-3 w-20">OTHER</th>
  </>
);

const Item: React.FC<IItem> = ({
  card,
  selected,
  checkItem,
  markAsUnconnected,
  deleteProduct,
  connectProfile,
}) => {
  const [isMarkAsUnconnectedModalOpen, setIsMarkAsUnconnectedModalOpen] =
    useState(false);
  const [isMarkAsUnconnectedConfirmed, setIsMarkAsUnconnectedConfirmed] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [isConnectProfileModalOpen, setIsConnectProfileModalOpen] =
    useState(false);

  const id = card.card_hash;

  return (
    <tr key={id}>
      <td className="p-4 pb-3 w-[5%]">
        <InputCheckbox
          id={`Checkbox-${status}-${id}`}
          label=""
          labelClassName=""
          value={selected}
          onChange={checkItem}
        />
      </td>
      <TypeCell card={card} />
      <CreatedCell card={card} />
      <ActivationKeyCell card={card} />
      <CardUrlCell card={card} />
      <ActionsMenu card={card}>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsMarkAsUnconnectedModalOpen(true);
            }}
            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
          >
            Mark as unconnected
          </button>
        </Menu.Item>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsConnectProfileModalOpen(true);
            }}
            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
          >
            Connect profile
          </button>
        </Menu.Item>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
            className="text-red-450 w-full text-left block px-4 py-2 text-sm"
          >
            Delete product
          </button>
        </Menu.Item>
      </ActionsMenu>
      {isMarkAsUnconnectedModalOpen && (
        <Modal
          isOpen={isMarkAsUnconnectedModalOpen}
          setIsOpen={setIsMarkAsUnconnectedModalOpen}
          onCancel={() => {
            setIsMarkAsUnconnectedModalOpen(false);
          }}
          dialogTitle="Are you sure you want to mark this card as unconnected?"
          onSuccess={() => {
            markAsUnconnected &&
              markAsUnconnected(card).then(() => {
                setIsMarkAsUnconnectedConfirmed(false);
                setIsMarkAsUnconnectedModalOpen(false);
              });
          }}
          checkbox={isMarkAsUnconnectedConfirmed}
          setCheckbox={setIsMarkAsUnconnectedConfirmed}
          isDisabled={!isMarkAsUnconnectedConfirmed}
          checkboxDescription="I am sure I want to mark this card as unconnected."
          successButtonText="Confirm"
          successButtonKind={BUTTON_KIND.RED}
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onCancel={() => {
            setIsDeleteModalOpen(false);
          }}
          dialogTitle="Deleting this product is permanent."
          dialogDescription="By proceeding, you acknowledge that you will permanently delete this card."
          onSuccess={() => {
            deleteProduct &&
              deleteProduct(card).then(() => {
                setIsDeleteConfirmed(false);
                setIsDeleteModalOpen(false);
              });
          }}
          checkbox={isDeleteConfirmed}
          setCheckbox={setIsDeleteConfirmed}
          isDisabled={!isDeleteConfirmed}
          checkboxDescription="I am sure I want to delete this card."
          successButtonText="Confirm"
          successButtonKind={BUTTON_KIND.RED}
        />
      )}
      {isConnectProfileModalOpen && (
        <ConnectProfileModal
          isOpen={isConnectProfileModalOpen}
          setIsOpen={setIsConnectProfileModalOpen}
          card={card}
          handleConnectProfile={connectProfile}
        />
      )}
    </tr>
  );
};

export const UnprogrammedTab: React.FC<IBaseTabState> = props => {
  return (
    <BaseTab
      {...props}
      name="Unprogrammed"
      status={CARD_STATUS.UNPROGRAMMED}
      header={Header}
      item={Item}
    />
  );
};
