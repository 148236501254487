import { useState } from 'react';

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { format, parseISO } from 'date-fns';

import accountsAPI from '@/api/accounts';
import Spinner from '@/components/Icons/Spinner';
import Modal from '@/components/Modals/Modal';
import ModalNoCancel from '@/components/Modals/ModalNoCancel';
import CancellationPolicyModal from '@/components/SettingsPage/ManagePlan/CancellationPolicyModal';
import PurchasingAdditionalCardModal from '@/components/SettingsPage/ManagePlan/PurchasingAdditionalCardModal';
import { getTierByVariantId } from '@/helpers/sealSubscriptions';
import {
  IPlatformSubscription,
  IPlatformSubscriptionBillingAttempt,
} from '@/types/IOrganisation';

interface PlanDetailProps {
  organisationId: number | undefined;
  subscription: IPlatformSubscription;
}

export default function PlanDetail(props: PlanDetailProps): JSX.Element {
  const { organisationId, subscription } = props;

  const [
    isPaymentMethodEmailSentModalOpen,
    setIsPaymentMethodEmailSentModalOpen,
  ] = useState(false);
  const [
    isPurchasingAdditionalCardModalOpen,
    setIsPurchasingAdditionalCardModalOpen,
  ] = useState(false);
  const [isCancellationPolicyModalOpen, setIsCancellationPolicyModalOpen] =
    useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isSubscriptionCancellationSent, setIsSubscriptionCancellationSent] =
    useState(false);
  const [
    isCancellationConfirmationModalOpen,
    setIsCancellationConfirmationModalOpen,
  ] = useState(false);
  const [isCancellationConfirmed, setIsCancellationConfirmed] = useState(false);

  const tier = getTierByVariantId(subscription.items[0].variant_id);
  const isFreeTier = !tier;
  const isScheduledForCancellation = subscription.cancelled_at !== null;

  const handleEditPaymentMethod = () => {
    if (!organisationId) return;

    setIsSendingEmail(true);
    accountsAPI
      .sendPaymentMethodUpdateEmail(organisationId)
      .then(() => {
        setIsPaymentMethodEmailSentModalOpen(true);
      })
      .finally(() => setIsSendingEmail(false));
  };

  const handleSendCancellationRequest = () => {
    if (!organisationId) return;

    accountsAPI.sendSubscriptionCancellationEmail(organisationId).then(() => {
      setIsSubscriptionCancellationSent(true);
    });
  };

  const getSubscriptionLabel = (subscription: IPlatformSubscription | null) => {
    if (!subscription) return '';

    const tier = getTierByVariantId(subscription.items[0].variant_id);
    if (!tier) return '1-4 profiles';

    return tier.label;
  };

  const getNextBillingAttemptDate = (
    attempts: IPlatformSubscriptionBillingAttempt[],
  ) => {
    if (!attempts) return null;

    const nextBillingAttempt = attempts.find(attempt => attempt.status === '');
    if (!nextBillingAttempt) return null;

    return format(parseISO(nextBillingAttempt.date), 'dd/MM/yy');
  };

  const renderBillingAttempt = (
    attempt: IPlatformSubscriptionBillingAttempt,
  ) => {
    return (
      <div
        id={attempt.id.toString()}
        className="flex gap-5 justify-between mt-6 whitespace-nowrap max-md:flex-wrap max-md:max-w-full"
      >
        <div className="text-gray-900">
          {format(parseISO(attempt.date), 'dd/MM/yy')}
        </div>

        {attempt.status === '' && (
          <div className="text-gray-500">Scheduled</div>
        )}
        {attempt.status === 'completed' && (
          <div className="text-gray-500">Paid</div>
        )}
      </div>
    );
  };

  const renderPaidBillingAttempts = (
    attempts: IPlatformSubscriptionBillingAttempt[],
  ) => {
    const paidAttempts = attempts.filter(
      attempt => attempt.status === 'completed',
    );

    return paidAttempts.map((attempt, index) => renderBillingAttempt(attempt));
  };

  const renderNextBillingAttempt = (
    attempts: IPlatformSubscriptionBillingAttempt[],
  ) => {
    const nextAttempt = attempts.find(attempt => attempt.status === '');

    if (!nextAttempt) return null;

    return renderBillingAttempt(nextAttempt);
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderNextBillingAttemptDate = () => {
    if (isScheduledForCancellation) return null;

    return (
      <p>
        <span className="text-gray-900">Renews every year: </span>
        next {getNextBillingAttemptDate(subscription?.billing_attempts ?? [])}
      </p>
    );
  };

  const renderCancellationDate = () => {
    if (!isScheduledForCancellation || !subscription.cancelled_at) return null;

    return (
      <section className="mt-3 leading-6 text-gray-900 max-md:max-w-full">
        <p>
          <span>Scheduled to be cancelled: </span>
          <span className="text-gray-500">
            {format(parseISO(subscription.cancelled_at), 'dd/MM/yy')}
          </span>
        </p>
      </section>
    );
  };

  return (
    <>
      <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
        <div className="flex flex-col w-[67%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
            <article className="flex flex-col justify-center p-6 text-base bg-white rounded-lg border border-gray-200 max-md:px-5 max-md:max-w-full">
              <header className="text-xl font-medium text-gray-900 max-md:max-w-full">
                Plan
              </header>
              <section className="flex flex-wrap gap-1.5 justify-between content-center mt-6 max-md:max-w-full">
                <p className="text-gray-900 grow leading-6 whitespace-nowrap">
                  Plan type:{' '}
                  <span className="text-gray-500">
                    {getSubscriptionLabel(subscription)}
                  </span>
                </p>
                <div className="flex gap-1.5 justify-between text-right leading-[150%] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                  <InformationCircleIcon className="w-6 aspect-square text-brand-500" />
                  <a
                    href="#"
                    onClick={() => setIsPurchasingAdditionalCardModalOpen(true)}
                    className="flex-auto underline text-gray-500"
                  >
                    Purchasing additional cards
                  </a>
                </div>
              </section>
              <section className="mt-3 leading-6 text-gray-900 max-md:max-w-full">
                <p>
                  Created at:{' '}
                  <span className="text-gray-500">
                    {format(
                      parseISO(subscription.order_placed),
                      'dd/MM/yy HH:mm a',
                    )}
                  </span>
                </p>
              </section>
              <section className="mt-3 leading-6 max-md:max-w-full">
                <p>
                  Status:{' '}
                  {subscription && (
                    <span className="text-emerald-400">Active</span>
                  )}
                  {!subscription && (
                    <span className="text-red-500">Inactive</span>
                  )}
                </p>
              </section>
              {renderCancellationDate()}
              {!isFreeTier && !isScheduledForCancellation && (
                <>
                  <section className="mt-3 leading-6 text-gray-500 max-md:max-w-full">
                    {renderNextBillingAttemptDate()}
                  </section>
                  <div className="shrink-0 mt-3 h-px bg-gray-300 max-md:max-w-full"></div>
                  <footer className="flex gap-5 justify-between mt-3 leading-[150%] max-md:flex-wrap max-md:max-w-full">
                    <a
                      onClick={() =>
                        setIsCancellationConfirmationModalOpen(true)
                      }
                      className="flex-auto text-red-400 underline cursor-pointer"
                    >
                      Cancel your platform fee
                    </a>
                    <a
                      onClick={() => setIsCancellationPolicyModalOpen(true)}
                      className="flex-auto text-right text-gray-500 underline cursor-pointer"
                    >
                      View cancellation policy
                    </a>
                  </footer>

                  {isSubscriptionCancellationSent && (
                    <div className="rounded-md bg-emerald-150 w-full p-3 mt-5">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-emerald-200"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-emerald-800">
                            Your request to cancel your platform fee has been
                            submitted.
                          </h3>
                          <p className="mt-2 text-sm leading-5 text-emerald-800">
                            An email will be sent once the cancellation has been
                            activated.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </article>
            <div className="flex flex-col justify-center p-6 mt-6 bg-white rounded-lg border border-solid border-gray-200 max-md:px-5 max-md:max-w-full">
              <div className="text-xl font-medium text-gray-900 max-md:max-w-full">
                Items
              </div>
              <div className="flex gap-5 justify-between mt-6 max-md:flex-wrap max-md:max-w-full">
                <div className="flex-auto text-base leading-6 text-gray-900">
                  Platform maintenance fee ({getSubscriptionLabel(subscription)}
                  )
                </div>
                <div className="text-lg font-medium leading-6 text-gray-500">
                  {subscription?.items[0].original_amount === 0
                    ? 'Free'
                    : `$${subscription?.items[0].original_amount}`}
                </div>
              </div>
              {!isFreeTier && (
                <div className="text-sm leading-6 font-normal text-gray-500">
                  <p>
                    <span className="font-medium">Please note:</span> This
                    platform utilisation fee is a recurring payment
                  </p>
                  <p>
                    The credit card details entered here will be used for each
                    annual payment.
                  </p>
                </div>
              )}
            </div>
            {!isFreeTier && subscription.billing_attempts.length > 0 && (
              <div className="flex flex-col justify-center p-6 mt-6 text-base leading-6 bg-white rounded-lg border border-solid border-gray-200 max-md:px-5 max-md:max-w-full">
                <div className="text-xl font-medium text-gray-900 max-md:max-w-full">
                  Billing
                </div>
                {renderPaidBillingAttempts(subscription.billing_attempts)}
                {renderNextBillingAttempt(subscription.billing_attempts)}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col max-md:mt-6">
            <div className="flex flex-col justify-center p-6 w-full bg-white rounded-lg border border-solid border-gray-200 max-md:px-5">
              <div className="text-xl font-medium text-gray-900">Customer</div>
              <div className="mt-3 text-base leading-6 text-gray-500">
                {subscription.first_name} {subscription.last_name}
              </div>
              <div className="shrink-0 mt-6 h-px bg-gray-300" />
              <div className="flex gap-5 justify-between mt-6 whitespace-nowrap">
                <div className="text-xl font-medium text-gray-900">
                  Purchasing Email
                </div>
              </div>
              <div className="mt-3 text-base leading-6 text-gray-500">
                {subscription.email}
              </div>
              <div className="shrink-0 mt-6 h-px bg-gray-300" />
              {subscription?.billing.first_name && (
                <>
                  <div className="flex gap-5 justify-between mt-6 whitespace-nowrap">
                    <div className="text-xl font-medium text-gray-900">
                      Billing address
                    </div>
                    <div className="my-auto text-lg leading-6">
                      <a
                        href="#"
                        onClick={handleEditPaymentMethod}
                        className="font-medium text-lg text-brand-500 flex w-full"
                      >
                        {isSendingEmail ? <Spinner /> : 'Edit'}
                      </a>
                    </div>
                  </div>
                  <div className="mt-3 text-base leading-6 text-gray-500">
                    {subscription?.billing.first_name}{' '}
                    {subscription?.billing.last_name}
                    <br />
                    {subscription?.billing.address1}{' '}
                    {subscription?.billing.address2}
                    <br />
                    {subscription?.billing.city},{' '}
                    {subscription?.billing.province} {subscription?.billing.zip}
                    <br />
                    {subscription?.billing.country}
                  </div>
                </>
              )}
              {subscription?.shipping.first_name && (
                <>
                  <div className="flex gap-5 justify-between mt-6 whitespace-nowrap">
                    <div className="text-xl font-medium text-gray-900">
                      Shipping address
                    </div>
                    <div className="my-auto text-lg leading-6">
                      <a
                        href="#"
                        onClick={handleEditPaymentMethod}
                        className="font-medium text-lg text-brand-500 flex w-full"
                      >
                        {isSendingEmail ? <Spinner /> : 'Edit'}
                      </a>
                    </div>
                  </div>
                  <div className="mt-3 text-base leading-6 text-gray-500">
                    {subscription?.shipping.first_name}{' '}
                    {subscription?.shipping.last_name}
                    <br />
                    {subscription?.shipping.address}
                    <br />
                    {subscription?.shipping.city},{' '}
                    {subscription?.shipping.province}{' '}
                    {subscription?.shipping.zip}
                    <br />
                    {subscription?.shipping.country}
                  </div>
                </>
              )}
            </div>
            {subscription.payment_method.brand && (
              <div className="flex flex-col justify-center p-6 mt-6 w-full bg-white rounded-lg border border-solid border-gray-200 max-md:px-5">
                <div className="flex gap-5 justify-between">
                  <div className="flex-auto text-xl font-medium text-gray-900">
                    Payment method
                  </div>
                  <div className="my-auto text-lg leading-6">
                    <a
                      href="#"
                      onClick={handleEditPaymentMethod}
                      className="font-medium text-lg text-brand-500 flex w-full"
                    >
                      {isSendingEmail ? <Spinner /> : 'Edit'}
                    </a>
                  </div>
                </div>
                <div className="flex gap-3 justify-between mt-3 text-base leading-6 text-gray-500">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/38ab422a8f9a6e8e676b6e0891b2728ecea62b51477fb9bd3ba78afb4b02067d?apiKey=d4ec77fc4cfe4e2284f8e5d80bc8c410&"
                    className="aspect-[1.59] w-[76px]"
                  />
                  <div>
                    {capitalize(subscription.payment_method.brand)} ending in{' '}
                    {subscription.payment_method.last4}
                    <br />
                    Expires on {subscription.payment_method.exp_month}/
                    {subscription.payment_method.exp_year}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <ModalNoCancel
          isOpen={isPaymentMethodEmailSentModalOpen}
          setIsOpen={setIsPaymentMethodEmailSentModalOpen}
          successButtonText={'OK'}
          onSuccess={() => setIsPaymentMethodEmailSentModalOpen(false)}
          dialogTitle={'Email sent!'}
          dialogDescription={
            'We have sent you an e-mail with a secure link to the page where you can update your payment details.'
          }
        />

        <PurchasingAdditionalCardModal
          isOpen={isPurchasingAdditionalCardModalOpen}
          setIsOpen={setIsPurchasingAdditionalCardModalOpen}
        />

        <CancellationPolicyModal
          isOpen={isCancellationPolicyModalOpen}
          setIsOpen={setIsCancellationPolicyModalOpen}
        />

        <Modal
          isOpen={isCancellationConfirmationModalOpen}
          setIsOpen={setIsCancellationConfirmationModalOpen}
          dialogTitle="Are you sure you want to cancel your platform fee?"
          dialogDescription={
            <>
              <p>
                We will be sad to see you go, but you can always renew your
                platform fee!
              </p>
              <br />
              <p>
                Your request to cancel will be sent. An account manager may be
                in contact with you to verify your cancellation. Otherwise, you
                will receive an email notification once your request has been
                processed.
              </p>
            </>
          }
          onSuccess={() => {
            setIsCancellationConfirmationModalOpen(false);
            handleSendCancellationRequest();
          }}
          onCancel={() => setIsCancellationConfirmationModalOpen(false)}
          checkbox={isCancellationConfirmed}
          setCheckbox={setIsCancellationConfirmed}
          isDisabled={!isCancellationConfirmed}
          checkboxDescription="I am sure I want to cancel my platform fee."
          successButtonText="Procceed"
        />
      </div>
    </>
  );
}
